<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
</mat-form-field>
<mat-card class="w-100">
  <mat-card-content>
    <mat-grid-list cols="1" rowHeight="50px">
      <mat-card-title>Catalogue List</mat-card-title>
      <mat-grid-tile class="d-block category-selector">
        <mat-form-field class="w-25 me-2">
          <mat-label>Select Type</mat-label>
          <mat-select (valueChange)="selectType($event)">
            <mat-option [value]="2">Pdf</mat-option>
            <mat-option [value]="3">Youtube Video</mat-option>
            <mat-option [value]="4">Other</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addCatalogue()">
          <span>Add Catalogue</span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="spinner" *ngIf="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div [hidden]="spinner" class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="text-nowrap w-100" matSort>
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element?.title }}</p>
          </td>
        </ng-container>

        <!-- Pdf Column -->
        <ng-container matColumnDef="file">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>File</th>
          <td mat-cell *matCellDef="let element">
            <!-- <div class="avatar" *ngIf="!element.image">
              {{ element.name.charAt(0).toUpperCase() }}
            </div> -->
            <!-- <img
              *ngIf="element.image !== null"
              src="{{ environmentURL + '/' + element.image }}"
              alt=""
              class="preview"
            /> -->
            <a *ngIf="element.type !== 2" href="{{element.fileURL}}" class="preview" frameborder="0" mat-raised-button
              color="primary" target="_blank">Link</a>
            <a *ngIf="element.type == 2" href="{{ environmentURL + '/' + element.fileURL }}" class="preview"
              frameborder="0" mat-raised-button color="primary" target="_blank">View Pdf</a>
            <!-- <p class="mb-0 fw-medium">{{ element.fileURL }}</p> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <button mat-icon-button aria-label="Edit" (click)="onEdit(element._id)">
              <mat-icon color="warn">edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" (click)="onDelete(element._id)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[20, 50, 75, 100]" aria-label="Select page of users"></mat-paginator>
  </mat-card-content>
</mat-card>