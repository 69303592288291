import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { SettingService } from 'src/app/service/setting.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  spinner: boolean = false;
  currentAppVersion!: string;
  updatedVersion: string = '1.0.2'
  socialForm = new FormGroup({
    instaLink: new FormControl(''),
    youtubeLink: new FormControl(''),
    facebookLink: new FormControl(''),
    linkedinLink: new FormControl(''),
    whatsAppLink: new FormControl(''),
  });
  isSubmitting = false;
  showInputFlag: boolean = false;
  termsConditionId!: string;
  termsConditionForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    pdf: new FormControl(),
  });
  preview: string | undefined;
  currentFile: File | undefined;
  pdfFileName: string | null = null;
  termsFlag: boolean = false;

  constructor(private settingsService: SettingService, private titleService: Title, private toastr: ToastrNotificationService) { }

  ngOnInit(): void {
    this.titleService.setTitle('Settings');
    this.getSocialLinks()
  }

  getAppVersion() {
    this.spinner = true
    this.settingsService.getAppVersion().subscribe({
      next: async (data) => {
        if (data.success) {
          this.currentAppVersion = data?.version;
          // this.toastr.success('Category added successfully', 'Success');
        } else {
          this.toastr.warning(
            data?.error?.message
              ? data?.error?.message
              : 'Could not get app version',
            'Warning'
          );
        }
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Categories',
          'Error'
        );
      },
      complete: () => { },
    })
  }

  getSocialLinks() {
    this.spinner = true
    this.settingsService.getSocialLinks().subscribe({
      next: async (data) => {
        if (data.success) {
          this.socialForm.patchValue({
            instaLink: data?.links?.instaLink,
            youtubeLink: data?.links?.youtubeLink,
            facebookLink: data?.links?.facebookLink,
            linkedinLink: data?.links?.linkedinLink,
            whatsAppLink: data?.links?.whatsAppLink,
          });
          // this.toastr.success('Category added successfully', 'Success');
        } else {
          this.toastr.warning(
            data?.error?.message
              ? data?.error?.message
              : 'Could not get app version',
            'Warning'
          );
        }
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Categories',
          'Error'
        );
      },
      complete: () => { },
    })
  }

  updateAppVersion() {
    this.spinner = true
    this.settingsService.updateAppVersion(this.updatedVersion).subscribe({
      next: async (data) => {
        if (data.success) {
          this.toastr.success('Version update notification sent to all user.', 'Success');
        } else {
          this.toastr.warning(
            data?.error?.message
              ? data?.error?.message
              : 'Could not update app version',
            'Warning'
          );
        }
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not update App version.',
          'Error'
        );
      },
      complete: () => { },
    })
  }

  updateTermsAndCondition() {
    this.termsFlag = true;
  }

  showInput() {
    this.showInputFlag = true;
  }

  buttonClick(link: any) {
    window.open(link, '_blank')
  }


  editLinks() {
    this.spinner = true;
    this.settingsService.updateSocialLinks(this.socialForm.value).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Social Links updated successfully', 'Success');
        } else {
          this.toastr.warning(
            data?.error?.message
              ? data?.error?.message
              : 'Could not update soical links',
            'Warning'
          );
        }
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not update social links.',
          'Error'
        );
      },
      complete: () => { }
    })
  }

  editTermsAndCondition() {
    const data = {
      termsConditionId: this.termsConditionId,
      title: this.termsConditionForm.value['title'],
      pdf: this.currentFile,
    };
    this.settingsService.updateTermsCondition(data).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Terms and Condition edited successfully', 'Success');
          this.termsFlag = false
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Terms and Condition could not be edited',
            'Warning'
          );
        }
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Terms and Condition could not be edited',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.currentFile = file;
      this.pdfFileName = file.name;
      // this.termsConditionForm.patchValue({
      //   pdf: file,
      // });
    }
  }

  onSubmit(type: number) {
    // 1 => Social Form
    // 2 => Terms and condition Form
    if (this.isSubmitting) {
      return;
    }
    this.showInputFlag = false;
    this.isSubmitting = true;
    if (type == 1) {
      this.editLinks()
    } else {
      this.editTermsAndCondition()
    }
    setTimeout(() => {
      // Reset the flag once the operation is complete
      this.isSubmitting = false;
    }, 3000);
  }
}
