<div class="card">
  <div class="card-body">
    <div class="mb-56">
      <h4 class="mb-56 d-inline" *ngIf="isAdd">Add Admin</h4>
      <h4 class="mb-56 d-inline" *ngIf="!isAdd">Edit Admin</h4>
      <div class="button-row mb-4 d-inline float-end">
        <button
          *ngIf="isAdd"
          mat-raised-button
          class="me-8 mb-8"
          [routerLink]="['../admin']"
          color="primary"
        >
          Admin List
        </button>
        <button
          *ngIf="!isAdd"
          mat-raised-button
          class="me-8 mb-8"
          [routerLink]="['../../admin']"
          color="primary"
        >
          Admin List
        </button>
      </div>
    </div>
    <form
      [formGroup]="adminForm"
      (ngSubmit)="onSubmit()"
      enctype="multipart/form-data"
      #myForm="ngForm"
    >
      <!-- Username -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Username</mat-label>
        <input
          matInput
          type="text"
          formControlName="username"
          placeholder="Enter your username"
        />
        <mat-error
          *ngIf="
            adminForm.controls['username'].hasError('required') ||
            myForm.submitted
          "
          >Username can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- Email -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          placeholder="Enter Email"
        />
        <mat-error
          *ngIf="
            adminForm.controls['email'].hasError('email') || myForm.submitted
          "
          >Please check the mail address again.</mat-error
        >
        <mat-error
          *ngIf="
            adminForm.controls['email'].hasError('required') || myForm.submitted
          "
          >Username can not be empty.</mat-error
        >
      </mat-form-field>
      <!-- Password -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          placeholder="Enter Password"
          minlength="6"
        />
        <button
          *ngIf="isAdd"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon matSuffix>{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
        <mat-error
          *ngIf="
            adminForm.controls['password'].hasError('required') ||
            myForm.submitted
          "
          >Password can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- Phone -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Phone</mat-label>
        <input
          matInput
          type="tel"
          formControlName="phone"
          placeholder="Enter phone number"
          maxlength="10"
        />
        <mat-error
          *ngIf="
            adminForm.controls['phone'].hasError('required') || myForm.submitted
          "
          >Phone number can not be empty.</mat-error
        >
        <mat-error
          *ngIf="
            adminForm.controls['phone'].hasError('pattern') || myForm.submitted
          "
          >Phone number must be number.</mat-error
        >
      </mat-form-field>

      <!-- Image -->
      <!-- <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Image</mat-label>
        <input
          matInput
          type="text"
          formControlName="image"
          placeholder="Enter image name"
          readonly
        />
      </mat-form-field> -->
      <!-- <input
        #fileInput
        type="file"
        id="file"
        accept="image/*"
        formControlName="image"
        (change)="onFileSelected($event)"
      /> -->

      <button
        type="button"
        mat-raised-button
        (click)="fileInput.click()"
        class="mb-3"
      >
        Choose File
      </button>
      <input
        hidden
        (change)="onFileSelected($event)"
        #fileInput
        accept="image/*"
        type="file"
        id="file"
        formControlName="image"
      />
      <br />
      <div>
        <img [src]="preview" class="preview mb-2" />
      </div>

      <button
        *ngIf="isAdd"
        [disabled]="adminForm.errors || adminForm.invalid"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Submit
      </button>
      <button
        *ngIf="!isAdd"
        mat-raised-button
        color="primary"
        type="button"
        (click)="onEdit()"
      >
        Edit
      </button>
    </form>
  </div>
</div>
