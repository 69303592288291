import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private baseService: BaseService) {}

  createProduct(data: any): Observable<any> {
    return this.baseService.post('product/addProduct', data);
  }

  updateProduct(data: any): Observable<any> {
    return this.baseService.patch(
      `product/editProduct/${data?.productId}`,
      data
    );
  }

  getAllProducts(): Observable<any> {
    return this.baseService.get('product/listProducts');
  }

  getProductById(data: any): Observable<any> {
    return this.baseService.get('product/getProduct', data);
  }

  deleteProduct(data: any): Observable<any> {
    return this.baseService.delete('product/delete', data);
  }

  getCategoryWiseProducts(data: any): Observable<any> {
    return this.baseService.get(`product/listProductsCategorywise/${data}`);
  }

  setProductOutofStock(data: any): Observable<any> {
    return this.baseService.patch(
      `product/setProductOutOfStock/${data.productId}`,
      data.inStock
    );
  }

  addBatchQuantity(data: any): Observable<any> {
    return this.baseService.post('batch/createBatch', data);
  }

  getAllBatches(): Observable<any> {
    return this.baseService.get('batch/listbatches');
  }
}
