import { Component } from '@angular/core';
import { UsersService } from 'src/app/service/users.service';
import { DeleteDialogBoxComponent } from '../delete-dialog-box/delete-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { EditDialogBoxComponent } from 'src/app/edit-dialog-box/edit-dialog-box.component';
import { AddDialogBoxComponent } from '../add-dialog-box/add-dialog-box.component';
import { AddStateDialogBoxComponent } from '../add-state-dialog-box/add-state-dialog-box.component';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-add-edit-city-state',
  templateUrl: './add-edit-city-state.component.html',
  styleUrls: ['./add-edit-city-state.component.scss']
})
export class AddEditCityStateComponent {

  states: any[] = [];
  cities: any[] = [];
  currentState!: string;
  isStateLoading = false; 
  isCityLoading: string | null = null;
  isLoading = false

  constructor(private userService: UsersService,private dialog: MatDialog, private toastr: ToastrNotificationService){}

  ngOnInit(): void {
    this.getAllState();
  }

  getAllState() {
    this.isStateLoading = true;
    this.userService.getState().subscribe({
      next: (data) => {
        this.states = data.states;
      },
      error: (error) => {},
      complete: () => this.isStateLoading = false
    });
  }

  getAllCity(state: string) {
    this.isCityLoading = state
    this.userService.getCity(state).subscribe({
      next: (data) => {
        this.cities = data.cities;
      },
      error: (error) => {},
      complete: () => {
        this.isCityLoading = null        
      }
    });
  }

  onPanelOpen(state: string): void {
    this.currentState = state;
    this.getAllCity(state)
    console.log('Expanded panel state:', this.currentState);
  }

  openDialog(): void {
    this.dialog.open(DeleteDialogBoxComponent, {
      width: '250px',
    });
  }

  openDeleteDialog(id: any, type: string): void {
    const dialogRef = this.dialog.open(DeleteDialogBoxComponent);
  
    dialogRef.afterClosed().subscribe(result => {
      
      if (result) {
        if (type === 'state') {
          this.isStateLoading = true; 
          this.userService.deleteState(id).subscribe({
            next: (response) => {
              if (response.success) {
                this.toastr.success('State Deleted Successfully', 'Success');
                this.getAllState();
              } else {
                console.error('State deletion failed:', response.error);
              }
            },
            error: (err) => {
              this.toastr.error(
                err.error.error ? err.error.error: 'Could not delete State',
                'Error'
              );
            },
            complete: () => this.isStateLoading = false
          });
        } else if (type === 'city') {
          this.isLoading = true;
          this.userService.deleteCity(id).subscribe({
            next: (response) => {
              if (response.success) {
                this.toastr.success('City Deleted Successfully', 'Success');
                this.getAllCity(this.currentState);
              } else {
                console.error('City deletion failed:', response.error);
              }
            },
            error: (err) => {
              this.toastr.error(
                err.error.error ? err.error.error : 'Could not delete City',
                'Error'
              );
            },
            complete: () => this.isLoading = false
          });
        } else {
          console.error('Unknown type:', type);
        }
      } else {
        console.log('Deletion canceled');
      }
    });
  }
  

  openEditDialog(id: any, type:string,initialValue: string): void {
    const dialogRef = this.dialog.open(EditDialogBoxComponent, {
      width: '500px',
      data: { id, type, initialValue }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result,'rt');
      
      if (result) {
        if (type === 'state') {
          const payload = {
            id: id,
            state: result.state,
            initialValue: initialValue
          };
          this.isStateLoading = true;
          this.userService.editState(payload).subscribe({
            next: (response) => {
              if (response.success) {
                this.toastr.success('State Edited Successfully', 'Success');
                this.getAllState();
              } else {
                console.error('State edited failed:', response.error);
              }
            },
            error: (err) => {
              this.toastr.error(
                err.error.error ? err.error.error : 'Could not Edited State',
                'Error'
              );
            },
            complete: () => this.isStateLoading = false
          });
        } else if (type === 'city') {
          const payload = {
            id: id,
            city: result.state,
            initialValue: initialValue
          };
          this.isLoading = true;
          this.userService.editCity(payload).subscribe({
            next: (response) => {
              if (response.success) {
                this.toastr.success('City Edited Successfully', 'Success');
                this.getAllCity(this.currentState);
              } else {
                console.error('City edited failed:', response.error);
              }
            },
            error: (err) => {
              this.toastr.error(
                err.error.error ? err.error.error : 'Could not Edited City',
                'Error'
              );
            },
            complete: () => this.isLoading = false
          });
        } else {
          console.error('Unknown type:', type);
        }
      } 
    });
  }

  openAddCityDialog(state: any): void {
    const dialogRef = this.dialog.open(AddDialogBoxComponent, {
      width: '500px',
      data: { state: state }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      
      if (result) {
        this.isLoading = true;
        this.userService.addCity(result).subscribe({
          next: (response) => {
            this.toastr.success('City Added Successfully', 'Success');
            this.getAllCity(result.state);
          },
          error: (err) => {
            this.toastr.error(
              err.error.error ? err.error.error : 'Could not Added City',
              'Error'
            );
          },
          complete: () => this.isLoading = false
        });
      }
    });
  }
  

  openAddStateDialog(): void {
    const dialogRef = this.dialog.open(AddStateDialogBoxComponent, {
      width: '500px',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isStateLoading = true;
        this.userService.addState(result).subscribe({
          next: (response) => {
            this.toastr.success('State Added Successfully', 'Success');
            this.getAllState();
          },
          error: (err) => {
            console.log(err);
            
            this.toastr.error(
              err.error.error ? err.error.error : 'Could not Added State',
              'Error'
            );
          },
          complete: () => this.isStateLoading = false
        });
      }
    });
  }
  
}