import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogueService } from 'src/app/service/catalogue.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-add-edit-catalogue',
  templateUrl: './add-edit-catalogue.component.html',
  styleUrls: ['./add-edit-catalogue.component.scss'],
})
export class AddEditCatalogueComponent {
  addTitle = 'Add Catalogue';
  editTitle = 'Edit Catalogue';
  hide = true;
  checked = true;
  isAdd = true;
  catalogueId!: string;
  catalogueForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    type: new FormControl('', [Validators.required]),
    pdf: new FormControl('', [Validators.required]),
  });
  preview: string | undefined;
  currentFile: File | undefined;
  isSubmitting = false;
  pdfFileName: string | null = null;
  catalogueType: any;

  constructor(
    private catalogueService: CatalogueService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.addTitle);
    this.catalogueId = this.route.snapshot.params['id'];
    if (this.catalogueId) {
      this.titleService.setTitle(this.editTitle);
      this.isAdd = false;
      this.getCatalogueById(this.catalogueId);
    }
  }

  getCatalogueById(id: string) {
    this.catalogueService.getCatalogueById(id).subscribe({
      next: (data) => {
        if (data.success) {
          this.catalogueForm.patchValue({
            title: data?.catalogues?.title,
            type: data?.catalogues?.type,
            pdf: data?.catalogues?.fileURL
          });
          this.catalogueType = data?.catalogues?.type
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Could not get Catalogue.',
            'Warning'
          );
        }
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not get Catalogue.',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onEdit() {
    // const data = {
    //   catalogueId: this.catalogueId,
    //   title: this.catalogueForm.value['title'],
    //   pdf: this.currentFile,
    // };
    var formdata: any = new FormData();
    if (this.catalogueType == 2) {
      formdata.append('catalogueId', this.catalogueId);
      formdata.append('pdf', this.currentFile);
      formdata.append('type', this.catalogueForm?.value['type']);
      formdata.append('title', this.catalogueForm?.value['title']);
    } else {
      formdata.append('catalogueId', this.catalogueId);
      formdata.append('title', this.catalogueForm?.value['title']);
      formdata.append('type', this.catalogueForm?.value['type']);
      formdata.append('file', this.catalogueForm?.value['pdf']);
    }
    this.catalogueService.updateCatalogue(formdata).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Catalogue edited successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Catalogue could not be edited',
            'Warning'
          );
        }
        this.router.navigate(['../../catalogue']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Catalogue could not be edited',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onAdd() {
    var formdata: any = new FormData();
    if (this.catalogueType == 2) {
      formdata.append('pdf', this.currentFile);
      formdata.append('title', this.catalogueForm?.value['title']);
      formdata.append('type', this.catalogueForm?.value['type']);
    } else {
      formdata.append('title', this.catalogueForm?.value['title']);
      formdata.append('type', this.catalogueForm?.value['type']);
      formdata.append('file', this.pdfFileName);
    }
    this.catalogueService.createCatalogue(formdata).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Catalogue added successfully', 'Success');
        } else {
          this.toastr.warning(
            data?.error?.message
              ? data?.error?.message
              : 'Catalogue could not be added',
            'Warning'
          );
        }
        this.router.navigate(['../catalogue']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Catalogue could not be added',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  selectType(event: any) {
    this.catalogueType = event;
    if (event !== 2) {
      this.catalogueForm.get('pdf')?.reset();
    }
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.currentFile = file;
      this.pdfFileName = file.name;
      
      // this.catalogueForm.patchValue({
      //   pdf: file,
      // });
    }
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }
    console.log('test::::::');
    
    this.isSubmitting = true;
    if (this.isAdd) {
      console.log('test');
      
      this.onAdd();
    } else {
      this.onEdit();
    }
    setTimeout(() => {
      // Reset the flag once the operation is complete
      this.isSubmitting = false;
    }, 3000);
  }
}
