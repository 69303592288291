import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FullComponent } from './layouts/full/full.component';
import { AdminComponent } from './components/admin/admin.component';
import { UsersComponent } from './components/users/users.component';
import { AddEditAdminComponent } from './components/admin/add-edit-admin/add-edit-admin.component';
import { AddEditUsersComponent } from './components/users/add-edit-users/add-edit-users.component';
import { CategoryComponent } from './components/category/category.component';
import { AddEditCategoryComponent } from './components/category/add-edit-category/add-edit-category.component';
// import { SubCategoryComponent } from './components/sub-category/sub-category.component';
// import { AddEditSubCategoryComponent } from './components/sub-category/add-edit-sub-category/add-edit-sub-category.component';
import { ProductsComponent } from './components/products/products.component';
import { AddEditProductComponent } from './components/products/add-edit-product/add-edit-product.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guard/auth.guard';
import { BatchListComponent } from './components/batch-list/batch-list.component';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { LeaderboardComponent } from './components/users/leaderboard/leaderboard.component';
import { AddEditCatalogueComponent } from './components/catalogue/add-edit-catalogue/add-edit-catalogue.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SliderComponent } from './components/slider/slider.component';
import { AddEditSliderComponent } from './components/slider/add-edit-slider/add-edit-slider.component';
import { OutletComponent } from './components/outlet/outlet.component';
import { AddEditOutletComponent } from './components/outlet/add-edit-outlet/add-edit-outlet.component';
import { AddEditCityStateComponent } from './components/add-edit-city-state/add-edit-city-state.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', component: DashboardComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'addAdmin', component: AddEditAdminComponent },
      { path: 'editAdmin/:id', component: AddEditAdminComponent },
      { path: 'users', component: UsersComponent },
      { path: 'addUser', component: AddEditUsersComponent },
      { path: 'editUser/:id', component: AddEditUsersComponent },
      { path: 'category', component: CategoryComponent },
      { path: 'addCategory', component: AddEditCategoryComponent },
      { path: 'editCategory/:id', component: AddEditCategoryComponent },
      // { path: 'subCategory', component: SubCategoryComponent },
      // { path: 'addSubCategory', component: AddEditSubCategoryComponent },
      // { path: 'editSubCategory/:id', component: AddEditSubCategoryComponent },
      { path: 'product', component: ProductsComponent },
      { path: 'addProduct', component: AddEditProductComponent },
      { path: 'editProduct/:id', component: AddEditProductComponent },
      { path: 'batchList', component: BatchListComponent },
      { path: 'catalogue', component: CatalogueComponent },
      { path: 'addCatalogue', component: AddEditCatalogueComponent },
      { path: 'editCatalogue/:id', component: AddEditCatalogueComponent },
      { path: 'slider', component: SliderComponent },
      { path: 'addSlider', component: AddEditSliderComponent },
      { path: 'editSlider/:id', component: AddEditSliderComponent },
      { path: 'outlet', component: OutletComponent },
      { path: 'addOutlet', component: AddEditOutletComponent },
      { path: 'editOutlet/:id', component: AddEditOutletComponent },
      { path: 'leaderboard', component: LeaderboardComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'addEditCityState', component: AddEditCityStateComponent}
    ],
  },

  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
