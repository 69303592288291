import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-add-edit-admin',
  templateUrl: './add-edit-admin.component.html',
  styleUrls: ['./add-edit-admin.component.scss'],
})
export class AddEditAdminComponent {
  addTitle = 'Add Admin';
  editTitle = 'Edit Admin';
  hide = true;
  checked = true;
  isAdd = true;
  adminId!: string;
  adminForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{10}$')
    ]),
    image: new FormControl(),
  });
  preview = '';
  currentFile: any;

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.addTitle);
    this.adminId = this.route.snapshot.params['id'];
    if (this.adminId) {
      this.titleService.setTitle(this.editTitle);
      this.isAdd = false;
      this.getAdminById(this.adminId);
    }
  }

  getAdminById(id: string) {
    this.adminService.getAdminById(id).subscribe({
      next: (data) => {
        this.adminForm.patchValue({
          username: data?.admin?.username,
          password: data?.admin?.password,
          email: data?.admin?.email,
          phone: data?.admin?.phone,
        });
        this.adminForm.controls['password'].disable();
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not get Admin Details',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onEdit() {
    const data = {
      adminId: this.adminId,
      username: this.adminForm.value['username'],
      email: this.adminForm.value['email'],
      // password: this.adminForm.value['password'],
      phone: this.adminForm.value['phone'],
    };
    this.adminService.updateAdmin(data).subscribe({
      next: (data) => {
        const user = {
          username: data.admin?.username,
          image: data.admin?.image,
        };
        localStorage.setItem('currentUser', JSON.stringify(user));
        if (data.success) {
          this.toastr.success('Admin edited successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Admin could not be edited',
            'Warning'
          );
        }
        this.router.navigate(['../../admin']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Admin could not be edited',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onAdd() {
    var formdata: any = new FormData();
    formdata.append('image', this.currentFile);
    formdata.append('email', this.adminForm?.value['email']);
    formdata.append('username', this.adminForm.value['username']);
    formdata.append('password', this.adminForm.value['password']);
    formdata.append('phone', this.adminForm.value['phone']);

    this.adminService.createAdmin(formdata).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Admin added successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Admin could not be added',
            'Warning'
          );
        }

        this.router.navigate(['../admin']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Admin could not be added',
          'Error'
        );
      },
      complete: () => {},
    });
  }
  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const file: File | null = selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  onSubmit() {
    if (this.isAdd) {
      this.onAdd();
    } else {
      this.onEdit();
    }
  }
}
