<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
</mat-form-field>
<mat-card class="w-100">
  <mat-card-content>
    <mat-grid-list cols="1" rowHeight="50px">
      <mat-card-title>Category List</mat-card-title>
      <mat-grid-tile>
        <button mat-raised-button color="primary" (click)="addCategory()">
          <span>Add Category</span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="spinner" *ngIf="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div [hidden]="spinner" class="table-responsive">
      <table
        mat-table
        [dataSource]="dataSource"
        class="text-nowrap w-100"
        matSort
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element.name }}</p>
          </td>
        </ng-container>

        <!-- Sticker Size Column -->
        <ng-container matColumnDef="stickerFlag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sticker Size
          </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.stickerFlag === 1">25 * 25</span>
            <span *ngIf="element.stickerFlag === 0">50 * 50</span>
          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Image</th>
          <td mat-cell *matCellDef="let element">
            <div class="avatar" *ngIf="!element.image">
              {{ element.name.charAt(0).toUpperCase() }}
            </div>
            <img
              *ngIf="element.image !== null"
              src="{{ environmentURL + '/' + element.image }}"
              alt=""
              class="preview"
            />
            <!-- <p class="mb-0 fw-medium">{{ element.image }}</p> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef class="text-center">Edit</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <button
              mat-icon-button
              aria-label="Edit"
              (click)="onEdit(element._id)"
            >
              <mat-icon color="warn">edit</mat-icon>
            </button>
            <!-- <button
              mat-icon-button
              aria-label="Delete"
              (click)="onDelete(element._id)"
            >
              <mat-icon color="warn">delete</mat-icon>
            </button> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[20, 50, 75, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
