<h1 mat-dialog-title>Add</h1>
<form [formGroup]="form" (ngSubmit)="onSave()">
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>State</mat-label>
      <input matInput formControlName="state" type="text" readonly/>
      <mat-error *ngIf="form.get('state')?.hasError('required')">
        State is required.
      </mat-error>
      <mat-error *ngIf="form.get('state')?.hasError('minlength')">
        State must be at least 3 characters long.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>City</mat-label>
      <input matInput formControlName="city" type="text"/>
      <mat-error *ngIf="form.get('city')?.hasError('required')">
        City is required.
      </mat-error>
      <mat-error *ngIf="form.get('city')?.hasError('minlength')">
        City must be at least 3 characters long.
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end p-2">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button type="submit" [disabled]="form.invalid">Save</button>
  </div>
</form>
