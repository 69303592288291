<div class="card">
  <div class="card-body">
    <div class="mb-56">
      <h4 class="mb-56 d-inline" *ngIf="isAdd">Add Slider</h4>
      <h4 class="mb-56 d-inline" *ngIf="!isAdd">Edit Slider</h4>
      <div class="button-row mb-4 d-inline float-end">
        <button *ngIf="isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../slider']" color="primary">
          Slider List
        </button>
        <button *ngIf="!isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../../slider']" color="primary">
          Slider List
        </button>
      </div>
    </div>
    <form [formGroup]="sliderForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data" #myForm="ngForm">
      <!-- Title -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" placeholder="Enter image title" />
        <mat-error *ngIf="
            sliderForm.controls['title'].hasError('required') ||
            myForm.submitted
          ">Image title can not be empty.</mat-error>
      </mat-form-field>

      <!-- Image -->
      <button type="button" mat-raised-button (click)="fileInput.click()" class="mb-3">
        Choose File
      </button>
      <input hidden (change)="fileChangeEvent($event)" #fileInput accept="image/*" type="file" id="file"
        formControlName="image" />
      <mat-error *ngIf="
          (sliderForm.controls['image'].hasError('required') &&
            sliderForm.controls['image'].touched) ||
          myForm.submitted
        ">Please select image.</mat-error>
      <image-cropper *ngIf="sliderForm.value['image']" [imageChangedEvent]="imageChangedEvent"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()" [maintainAspectRatio]="true" [aspectRatio]="1638 / 819"
        [cropperMinWidth]="1638" [cropperMinHeight]="819" [cropperMaxWidth]="1638"
        [cropperMaxHeight]="819"></image-cropper>
      <br />
      <div>
        <img [src]="croppedImage" class="preview mb-2" />
      </div>

      <button *ngIf="isAdd" mat-raised-button color="primary"
        [disabled]="sliderForm.errors || sliderForm.invalid || isSubmitting" type="submit">
        Submit
      </button>
      <button *ngIf="!isAdd" mat-raised-button color="primary" type="button" (click)="onEdit()">
        Edit
      </button>
    </form>
  </div>
</div>