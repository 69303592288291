import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class SliderService {
  constructor(private baseService: BaseService) {}

  createSlider(data: any): Observable<any> {
    return this.baseService.post('slider/addSlider', data);
  }

  updateSlider(data: any): Observable<any> {
    return this.baseService.post(`slider/editSlider`, data);
  }

  getAllSliders(): Observable<any> {
    return this.baseService.get('slider/listSlider');
  }

  getSliderById(data: any): Observable<any> {
    return this.baseService.get('slider/getSlider', data);
  }

  deleteSlider(data: any): Observable<any> {
    return this.baseService.delete('slider/deleteSlider', data);
  }
}
