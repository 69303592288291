import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/service/category.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent {
  displayedColumns: string[] = ['name', 'stickerFlag', 'image', 'edit'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = true;

  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Category List');
    this.getAllCategories();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllCategories() {
    this.categoryService.getAllCategories().subscribe({
      next: async (categories) => {
        this.dataSource = await new MatTableDataSource(categories.categories);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Categories',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  addCategory() {
    this.router.navigate(['/addCategory']);
  }

  // onDelete(id: string) {
  //   console.log(id);
  //   this.categoryService.deleteCategory(id).subscribe({
  //     next: (data) => {
  //       console.log(data);
  //       this.getAllCategories();
  //     },
  //     error: (error) => {
  //       console.log(error);
  //     },
  //     complete: () => {},
  //   });
  // }

  onEdit(id: string) {
    this.router.navigate(['/editCategory/', id]);
  }
}
