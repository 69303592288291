<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
</mat-form-field>
<mat-card class="w-100">
  <mat-card-content>
    <mat-grid-list cols="1" rowHeight="50px">
      <mat-card-title>Slider List</mat-card-title>
      <mat-grid-tile>
        <button mat-raised-button color="primary" (click)="addSlider()">
          <span>Add Slider</span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="spinner" *ngIf="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!spinner" class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="text-nowrap w-100" matSort>
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 ms-2 fw-medium d-inline-block">
              {{ element.title }}
            </p>
          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Image</th>
          <td mat-cell *matCellDef="let element">
            <div class="avatar" *ngIf="!element.imageUrl">
              {{ element?.title?.charAt(0).toUpperCase() }}
            </div>
            <img *ngIf="element.imageURL !== null" src="{{ environmentURL + '/' + element?.imageUrl }}"
              alt="{{ element?.title }}" class="preview" />
          </td>
        </ng-container>

        <!-- Action -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <button mat-icon-button aria-label="Edit" (click)="onEdit(element._id)">
              <mat-icon color="warn">edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" (click)="onDelete(element._id)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
  </mat-card-content>
</mat-card>