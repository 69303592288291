<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
</mat-form-field>
<mat-card class="w-100">
  <mat-card-content>
    <mat-grid-list cols="1" rowHeight="50px">
      <mat-card-title>Batch List</mat-card-title>
      <!-- <mat-grid-tile>
          <button mat-raised-button color="primary" (click)="addCategory()">
            <span>Add Category</span>
          </button>
        </mat-grid-tile> -->
    </mat-grid-list>
    <div class="spinner" *ngIf="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div [hidden]="spinner" class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="text-nowrap w-100" matSort>
        <!-- Name Column -->
        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Product Name
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element.productName }}</p>
          </td>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element.quantity }}</p>
          </td>
        </ng-container>

        <!-- Register Date Column -->
        <ng-container matColumnDef="registerDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Registerd Date
          </th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">
              {{ element.registerDate | date : "dd/MM/yyyy hh:mm" }}
            </p>
          </td>
        </ng-container>

        <!-- Register Date Column -->
        <ng-container matColumnDef="stickerFlag">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Sticker Size
          </th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="element.stickerFlag == 1" class="mb-0 fw-medium">
              25 * 25
            </p>
            <p *ngIf="element.stickerFlag == 0" class="mb-0 fw-medium">
              50 * 50
            </p>
          </td>
        </ng-container>

        <!-- Action -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <!-- <button
                mat-icon-button
                aria-label="Edit"
                (click)="onEdit(element._id)"
              >
                <mat-icon color="warn">edit</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="Delete"
                (click)="onDelete(element._id)"
              >
                <mat-icon color="warn">delete</mat-icon>
              </button> -->
            <div *ngFor="let qrCode of myAngularxQrCode" class="d-inline-grid">
              <qr-code [id]="qrCode" [hidden]="myAngularxQrCode" [value]="qrCode" size="20" errorCorrectionLevel="L">
              </qr-code>

            </div>
            <a (click)="onDownload(element)" mat-raised-button download="qrcode">Download</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[20, 50, 75, 100]" aria-label="Select page of users"></mat-paginator>
  </mat-card-content>
</mat-card>