<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
</mat-form-field>
<mat-card class="w-100">
  <mat-card-content>
    <mat-grid-list cols="1" rowHeight="50px">
      <mat-card-title>Product List</mat-card-title>
      <mat-grid-tile class="d-block category-selector">
        <mat-form-field class="w-25 me-2">
          <mat-label>Select Category</mat-label>
          <mat-select (valueChange)="selectCategory($event)">
            <mat-option></mat-option>
            <mat-option
              [value]="option._id"
              *ngFor="let option of categoryWiseProducts"
              >{{ option.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="addProduct()">
          <span>Add Product</span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="spinner" *ngIf="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div [hidden]="spinner" class="table-responsive">
      <table
        mat-table
        [dataSource]="dataSource"
        class="text-nowrap w-100"
        matSort
      >
        <!-- Image Column -->
        <!-- <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Image</th>
          <td mat-cell *matCellDef="let element">
            <div class="avatar" *ngIf="element.images.length == 0">
              {{ element.name.charAt(0).toUpperCase() }}
            </div>
            <img
              *ngIf="element.images !== null"
              src="{{ environmentURL + '/' + element.images }}"
              alt=""
              class="preview"
            />
          </td>
        </ng-container> -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onEdit(element._id, false)"
          >
            <div
              class="avatar d-inline-flex"
              *ngIf="element?.images?.length == 0 || !element.images"
            >
              {{ element.name.charAt(0).toUpperCase() }}
            </div>
            <img
              *ngIf="element?.images?.length > 0"
              src="{{ environmentURL + '/' + element?.images[0] }}"
              alt=""
              class="preview"
            />
            <p class="mb-0 ms-2 fw-medium d-inline-block">{{ element.name }}</p>
          </td>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Warranty</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onEdit(element._id, false)"
          >
            <p class="mb-0 fw-medium">
              {{ element?.warrentyPeriodInYears }} year
            </p>
          </td>
        </ng-container>

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onEdit(element._id, false)"
          >
            <p class="mb-0 fw-medium multiline">{{ element?.description }}</p>
          </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Category Name
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onEdit(element._id, false)"
          >
            <p class="mb-0 fw-medium">{{ element?.category?.name }}</p>
          </td>
        </ng-container>

        <!-- Out of Stock Column -->
        <!-- <ng-container matColumnDef="inStock">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Stock</th>
          <td mat-cell *matCellDef="let element" class="pointer">
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="inStock"
              [checked]="element?.inStock"
              (click)="setProductOutOfStock(element._id, inStock)"
            >
              {{ element?.inStock === true ? "In Stock" : "Out of Stock" }}
            </mat-slide-toggle>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef class="text-center">Edit</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <button
              mat-icon-button
              aria-label="Edit"
              (click)="onEdit(element._id, true)"
            >
              <mat-icon color="warn">edit</mat-icon>
            </button>
            <!-- <button
              mat-icon-button
              aria-label="Delete"
              (click)="onDelete(element._id)"
            >
              <mat-icon color="warn">delete</mat-icon>
            </button> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator
      [pageSizeOptions]="[20, 50, 75, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
