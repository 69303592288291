import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CatalogueService } from 'src/app/service/catalogue.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss'],
})
export class CatalogueComponent {
  displayedColumns: string[] = ['title', 'file', 'action'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = false;
  typeWiseCatalogue: any;
  catalogueType: any;

  constructor(
    private catalogueService: CatalogueService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Catalogue List');
    // this.getAllCatalogues();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllCatalogues(type: number) {
    this.spinner = true
    this.catalogueService.getAllCatalogues(type).subscribe({
      next: async (catalogues) => {
        this.dataSource = await new MatTableDataSource(catalogues.catalogues);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Catalogues',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  addCatalogue() {
    this.router.navigate(['/addCatalogue']);
  }

  selectType(event: any) {
    this.catalogueType = event;
    this.getAllCatalogues(this.catalogueType);
  }

  onDelete(id: string) {
    this.catalogueService.deleteCatalogue(id).subscribe({
      next: (data) => {
        this.getAllCatalogues(this.catalogueType);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not delete Catalogue',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onEdit(id: string) {
    this.router.navigate(['/editCatalogue/', id]);
  }
}
