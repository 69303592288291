import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-add-state-dialog-box',
  templateUrl: './add-state-dialog-box.component.html',
  styleUrls: ['./add-state-dialog-box.component.scss']
})
export class AddStateDialogBoxComponent {
  public form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddStateDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(){
    this.initializeForm()
  }

    initializeForm(){
    this.form = this.fb.group({
      state: ['', [Validators.required, Validators.minLength(3)]],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
