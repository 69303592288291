<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
</mat-form-field>
<mat-card class="w-100">
  <mat-card-content>
    <mat-grid-list cols="1" rowHeight="50px">
      <mat-card-title>Outlet List</mat-card-title>
      <mat-grid-tile>
        <button mat-raised-button color="primary" (click)="addOutlet()">
          <span>Add Outlet</span>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
    <div class="spinner" *ngIf="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div [hidden]="spinner" class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="text-nowrap w-100" matSort>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element.name }}</p>
          </td>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element.city }}</p>
          </td>
        </ng-container>

        <!-- Pincode Column -->
        <ng-container matColumnDef="pincode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Pincode</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element.pincode }}</p>
          </td>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contact</th>
          <td mat-cell *matCellDef="let element">
            <p class="mb-0 fw-medium">{{ element.contact }}</p>
          </td>
        </ng-container>

        <!-- Location Column -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
          <td mat-cell *matCellDef="let element">
            <a href="{{ element.location }}" mat-raised-button color="primary" target="_blank">Link</a>
            <!-- <p class="mb-0 fw-medium">{{ element.location }}</p> -->
          </td>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Image</th>
          <td mat-cell *matCellDef="let element">
            <div class="avatar" *ngIf="!element.imageUrl">
              {{ element.name.charAt(0).toUpperCase() }}
            </div>
            <img *ngIf="element.imageUrl !== null" src="{{ environmentURL + '/' + element.imageUrl }}"
              alt="{{element?.name}}" class="preview" />
            <!-- <p class="mb-0 fw-medium">{{ element.image }}</p> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <button mat-icon-button aria-label="Edit" (click)="onEdit(element._id)">
              <mat-icon color="warn">edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" (click)="onDelete(element._id)">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            No data matching the filter "{{ input.value }}"
          </td>
        </tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[20, 50, 75, 100]" aria-label="Select page of users"></mat-paginator>
  </mat-card-content>
</mat-card>