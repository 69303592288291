<div class="card">
    <div class="card-body">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <p class="m-b-4 mb-0">List of State</p>
            <button mat-raised-button color="primary" class="btn btn-primary mb-16" (click)="openAddStateDialog()">
              <span>Add State</span>
            </button>
        </div>

        <div *ngIf="isStateLoading" class="loader1">
          <mat-spinner></mat-spinner>
        </div>

        <!--state -->
            <mat-accordion>
                <mat-expansion-panel *ngFor="let state of states" class="mb-16" (opened)="onPanelOpen(state.state)">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ state.state }}
                      </mat-panel-title>
                      <div *ngIf="isCityLoading === state.state || isLoading" class="loader">
                        <mat-spinner></mat-spinner>
                      </div>
                      <button mat-icon-button (click)="openEditDialog(state._id, 'state',state.state); $event.stopPropagation()">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button (click)="openDeleteDialog(state._id, 'state'); $event.stopPropagation()">
                        <mat-icon>delete</mat-icon>
                      </button>
                      <button mat-icon-button (click)="openAddCityDialog(state.state); $event.stopPropagation()">
                        <mat-icon>add</mat-icon>
                      </button>
                    </mat-expansion-panel-header>
                    <ul class="list-group">                      
                      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let city of cities">
                        {{ city.city }}
                        <div>
                          <button class="btn btn-outline-primary btn-sm" mat-icon-button (click)="openEditDialog(city._id,'city',city.city)" >
                            <mat-icon>edit</mat-icon>
                          </button>
                          <button class="btn btn-outline-danger btn-sm" mat-icon-button (click)="openDeleteDialog(city._id,'city')">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </li>
                    </ul>
                  </mat-expansion-panel>
                  <ng-container>

                  </ng-container>
            </mat-accordion>
    </div>  
  </div>