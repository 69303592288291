<div class="card">
  <div class="card-body">
    <div class="mb-56">
      <h4 class="mb-56 d-inline" *ngIf="isAdd">Add Catalogue</h4>
      <h4 class="mb-56 d-inline" *ngIf="!isAdd">Edit Catalogue</h4>
      <div class="button-row mb-4 d-inline float-end">
        <button *ngIf="isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../catalogue']" color="primary">
          Catalogue List
        </button>
        <button *ngIf="!isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../../catalogue']" color="primary">
          Catalogue List
        </button>
      </div>
    </div>
    <form [formGroup]="catalogueForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data" #myForm="ngForm">
      <!-- Catalogue name -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Catalogue Title</mat-label>
        <input matInput type="text" formControlName="title" placeholder="Enter the Catalogue Title" />
        <mat-error *ngIf="
            catalogueForm.controls['title'].hasError('required') ||
            myForm.submitted
          ">Title can not be empty.</mat-error>
      </mat-form-field>

      <!-- Catalogue Type -->
      <mat-form-field class="w-25 fs-16" appearance="outline">
        <mat-label>Select Type</mat-label>
        <mat-select formControlName="type" (valueChange)="selectType($event)">
          <mat-option [value]="2">Pdf</mat-option>
          <mat-option [value]="3">Youtube Video</mat-option>
          <mat-option [value]="4">Other</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Pdf -->
      <div *ngIf="catalogueType == 2">
        <button type="button" mat-raised-button (click)="fileInput.click()" class="mb-3">
          Choose File
        </button>
        <input hidden #fileInput accept="application/pdf" type="file" id="file" formControlName="pdf"
          (change)="onFileChange($event)" />
        <p *ngIf="pdfFileName" class="d-inline-block ms-3">{{ pdfFileName }}</p>
      </div>
      <div *ngIf="catalogueType !== 2">
        <mat-form-field class="w-100 fs-16" appearance="outline">
          <mat-label>Catalogue Link</mat-label>
          <input matInput type="text" formControlName="pdf" placeholder="Enter the Link here" />
          <mat-error *ngIf="
              catalogueForm.controls['pdf'].hasError('required') ||
              myForm.submitted
            ">Link can not be empty.</mat-error>
        </mat-form-field>
      </div>
      <br />

      <button *ngIf="isAdd" [disabled]="
          catalogueForm.errors || catalogueForm.invalid || isSubmitting
        " mat-raised-button color="primary" type="submit">
        Submit
      </button>
      <button *ngIf="!isAdd" mat-raised-button color="primary" type="button" (click)="onEdit()">
        Edit
      </button>
    </form>
  </div>
</div>