<mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input />
  </mat-form-field>
  
  <mat-card class="w-100">
    <mat-card-content>
      <mat-grid-list cols="1" rowHeight="50px">
        <mat-card-title>Leaderboard</mat-card-title>
        <mat-grid-tile class="d-block category-selector">
          <mat-form-field class="w-25 me-2">
            <mat-label>Select Category</mat-label>
            <mat-select (valueChange)="selectCategory($event)">
              <mat-option [value]="option._id" *ngFor="let option of categoryWiseProducts">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field class="example-form-field">
            <mat-label>Date Filter</mat-label>
            <mat-date-range-input
              [formGroup]="campaignOne"
              [rangePicker]="campaignOnePicker">
              <input matStartDate placeholder="Start date" formControlName="start">
              <input matEndDate placeholder="End date" formControlName="end">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>
  
          <button mat-raised-button color="primary" (click)="exportToExcel()">
            <span>Export to excel</span>
          </button>
        </mat-grid-tile>
      </mat-grid-list>
  
      <div class="spinner" *ngIf="spinner">
        <mat-spinner></mat-spinner>
      </div>
  
      <div [hidden]="spinner" class="table-responsive">
        <table mat-table [dataSource]="dataSource" class="text-nowrap w-100" matSort>
          <!-- Username Column -->
          <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
            <td mat-cell *matCellDef="let element">
              <p class="mb-0 fw-medium">{{ element.username }}</p>
            </td>
          </ng-container>
  
          <!-- Address Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Address</th>
            <td mat-cell *matCellDef="let element">
              <p class="mb-0 fw-medium">City: {{ element.city }}</p>
              <p class="mb-0 fw-medium">State: {{ element.state }}</p>
              <p class="mb-0 fw-medium">Pincode: {{ element.pincode }}</p>
            </td>
          </ng-container>
  
          <!-- Phone Column -->
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let element">
              <p class="mb-0 fw-medium">{{ element.phone }}</p>
            </td>
          </ng-container>
  
          <!-- Image Column -->
          <ng-container matColumnDef="image">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Image</th>
            <td mat-cell *matCellDef="let element">
              <div class="avatar" *ngIf="!element.image">
                {{ element.username.charAt(0).toUpperCase() }}
              </div>
              <img *ngIf="element.image" [src]="environmentURL + '/' + element.image" alt="" class="preview" />
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              No data matching the filter "{{ input.value }}"
            </td>
          </tr>
        </table>
      </div>
  
      <mat-paginator [pageSizeOptions]="[20, 50, 75, 100]" aria-label="Select page of users"></mat-paginator>
    </mat-card-content>
  </mat-card>
  