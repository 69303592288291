import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private baseService: BaseService) {}

  createCategory(data: any): Observable<any> {
    return this.baseService.post('category/addCategory', data);
  }

  updateCategory(data: any): Observable<any> {
    return this.baseService.patch(
      `category/updateCategory/${data?.categoryId}`,
      data
    );
  }

  getAllCategories(): Observable<any> {
    return this.baseService.get('category/listCategory');
  }

  getCategoryById(data: any): Observable<any> {
    return this.baseService.get('category/getCategory', data);
  }

  deleteCategory(data: any): Observable<any> {
    return this.baseService.delete('category/delete', data);
  }
}
