import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CatalogueService {
  constructor(private baseService: BaseService) { }

  createCatalogue(data: any): Observable<any> {
    return this.baseService.post('catalogue/addCatalogue', data);
  }

  updateCatalogue(data: any): Observable<any> {
    return this.baseService.post(`catalogue/editCatalogue`, data);
  }

  getAllCatalogues(type: number): Observable<any> {
    return this.baseService.get(`catalogue/listCatalogue/${type}`);
  }

  getCatalogueById(data: any): Observable<any> {
    return this.baseService.get('catalogue/getCatalogue', data);
  }

  deleteCatalogue(data: any): Observable<any> {
    return this.baseService.delete('catalogue/deleteCatalogue', data);
  }
}
