import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OutletService } from 'src/app/service/outlet.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html',
  styleUrls: ['./outlet.component.scss']
})
export class OutletComponent {
  displayedColumns: string[] = [
    'name',
    'city',
    'pincode',
    'contact',
    'location',
    'image',
    'action',
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = true;

  constructor(
    private outletService: OutletService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Outlet List');
    this.getAllOutlets();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllOutlets() {
    this.outletService.getAllOutlets().subscribe({
      next: async (outlets) => {
        this.dataSource = await new MatTableDataSource(
          outlets.outlets
        );
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Outlets',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  addOutlet() {
    this.router.navigate(['/addOutlet']);
  }

  onDelete(id: string) {
    this.outletService.deleteOutlet(id).subscribe({
      next: (data) => {
        this.getAllOutlets();
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not delete Outlet',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onEdit(id: string) {
    this.router.navigate(['/editOutlet/', id]);
  }
}
