import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private baseService: BaseService) {}

  createAdmin(data: any): Observable<any> {
    return this.baseService.post('admin/addAdmin', data);
  }

  updateAdmin(data: any): Observable<any> {
    return this.baseService.patch('admin/editAdmin', data);
  }

  getAllAdmins(): Observable<any> {
    return this.baseService.get('admin/listAdmins');
  }

  getAdminById(data: any): Observable<any> {
    return this.baseService.get('admin/getAdmin', data);
  }

  deleteAdmin(data: any): Observable<any> {
    return this.baseService.delete('admin/deleteAdmin', data);
  }
}
