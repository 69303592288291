import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { UsersService } from 'src/app/service/users.service';

@Component({
  selector: 'app-add-edit-users',
  templateUrl: './add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss'],
})
export class AddEditUsersComponent {
  addTitle = 'Add User';
  editTitle = 'Edit User';
  hide = true;
  checked = true;
  isAdd = true;
  userId!: string;
  userForm = new FormGroup({
    companyName: new FormControl('', [Validators.required]),
    username: new FormControl('', [Validators.required]),
    // email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    phone: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]{10}$'),
    ]),
    state: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [
      Validators.required,
      Validators.pattern('^[1-9][0-9]{5}$'),
    ]),
    image: new FormControl(),
  });
  preview: string | undefined;
  currentFile: File | undefined;
  states: any;
  cities: any;
  filteredState: any;
  filteredCity: any;

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.addTitle);
    this.userId = this.route.snapshot.params['id'];
    if (this.userId) {
      this.titleService.setTitle(this.editTitle);
      this.isAdd = false;
      this.getUserById(this.userId);
    }
    this.getAllState();
    this.userForm.controls['city'].disable();
  }

  getUserById(id: string) {
    this.userService.getUserById(id).subscribe({
      next: (data) => {
        this.userForm.patchValue({
          companyName: data?.user?.companyName,
          username: data?.user?.username,
          password: data?.user?.password,
          // email: data?.user?.email,
          phone: data?.user?.phone,
          city: data?.user?.city,
          state: data?.user?.state,
          pincode: data?.user?.pincode,
        });
        this.userForm.controls['password'].disable();
        this.userForm.controls['city'].enable();
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not load user',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  private _filterState(name: string): any {
    const filterValue = name?.toLowerCase();
    return this.states.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _filterCity(name: string): any {
    const filterValue = name?.toLowerCase();
    return this.cities.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  getAllState() {
    this.userService.getState().subscribe({
      next: (data) => {
    
        this.states = data.states.map((state: any) => state.state);
    
        this.filteredState = this.userForm.get('state')?.valueChanges.pipe(
          startWith(''),
          map((state: string | null) => {
            const stateValue = state ?? '';
            return this._filterState(stateValue);
          })
        );
      },
      error: (error) => {
        console.error('Error fetching states', error);
      },
      complete: () => {
        // Optionally handle completion
        console.log('State fetch complete');
      },
    });
  }

  stateSelected(event: MatAutocompleteSelectedEvent) {
    const state = event?.option.value;
    this.getAllCity(state);
  }

  getAllCity(state: string) {
    this.userService.getCity(state).subscribe({
      next: (data) => {
        console.log(data);
        
        this.cities = data.cities.map((cities: any)=> cities.city);
        this.userForm.controls['city'].enable();
        this.filteredCity = this.userForm.get('city')?.valueChanges.pipe(
          startWith(''),
          map((city: any) => {
            return city ? this._filterCity(city || '') : this.cities?.slice();
          })
        );
      },
      error: (error) => {},
      complete: () => {},
    });
  }

  onEdit() {
    const data = {
      userId: this.userId,
      companyName: this.userForm.value['companyName'],
      username: this.userForm.value['username'],
      // email: this.userForm.value['email'],
      // password: this.userForm.value['password'],
      phone: this.userForm.value['phone'],
      state: this.userForm.value['state'],
      city: this.userForm.value['city'],
      pincode: this.userForm.value['pincode'],
      image: this.currentFile
    };

    this.userService.updateUser(data).subscribe({ 
      next: (data) => {
        if (data.success) {
          this.toastr.success('User edited successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'User could not be edited',
            'Warning'
          );
        }
        this.router.navigate(['../../users']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'User could not be edited',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onAdd() {
    var formdata: any = new FormData();
    formdata.append('image', this.currentFile);
    // formdata.append('email', this.userForm?.value['email']);
    formdata.append('username', this.userForm?.value['username']);
    formdata.append('companyName', this.userForm?.value['companyName']);
    formdata.append('password', this.userForm?.value['password']);
    formdata.append('phone', this.userForm?.value['phone']);
    formdata.append('state', this.userForm?.value['state']);
    formdata.append('city', this.userForm?.value['city']);
    formdata.append('pincode', this.userForm?.value['pincode']);
    this.userService.createUser(formdata).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('User added successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.messsage
              ? data.error.message
              : 'User could not be added',
            'Warning'
          );
        }
        this.router.navigate(['../users']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.messsage : 'User could not be added',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const file: File | null = selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  onSubmit() {
    if (this.isAdd) {
      this.onAdd();
    } else {
      this.onEdit();
    }
  }
}
