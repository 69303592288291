<h1 mat-dialog-title>Edit {{data.type}}</h1>
<form [formGroup]="form">
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>{{data.type}}</mat-label>
      <input matInput formControlName="state" type="text"/>
      <mat-error *ngIf="form.get('state')?.hasError('required')">
        Name is required.
      </mat-error>
      <mat-error *ngIf="form.get('state')?.hasError('minlength')">
        Name must be at least 3 characters long.
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end p-2">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button (click)="onSave()" [disabled]="form.invalid">Save</button>
  </div>
</form>
