<div class="card">
  <div class="card-body">
    <div class="mb-26">
      <h4 class="mb-56 d-inline" *ngIf="isAdd">Add Product</h4>
      <h4 class="mb-56 d-inline" *ngIf="!isAdd">Edit Product</h4>
      <div class="button-row mb-4 d-inline float-end">
        <button *ngIf="isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../product']" color="primary">
          Product List
        </button>
        <button *ngIf="!isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../../product']" color="primary">
          Product List
        </button>
      </div>
    </div>
    <div class="spinner" *ngIf="spinner">
      <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data" #myForm="ngForm"
      [hidden]="spinner">
      <!-- Product Name -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Product Name</mat-label>
        <input matInput type="text" formControlName="name" placeholder="Enter product name" />
        <mat-error *ngIf="
            productForm.controls['name'].hasError('required') ||
            myForm.submitted
          ">Name can not be empty.</mat-error>
      </mat-form-field>

      <!-- Warrenty Period In Years -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Warrenty Period In Years</mat-label>
        <input matInput type="number" formControlName="warrentyPeriodInYears"
          placeholder="Enter Warrenty Period In Years"/>
        <mat-error *ngIf="
            productForm.controls['warrentyPeriodInYears'].hasError(
              'required'
            ) || myForm.submitted
          ">Warrenty can not be empty.</mat-error>
      </mat-form-field>

      <!-- Description -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="description" placeholder="Enter product description"
          maxlength="100" />
        <mat-error *ngIf="
            productForm.controls['description'].hasError('required') ||
            myForm.submitted
          ">Description can not be empty.</mat-error>
      </mat-form-field>

      <!-- Category -->
      <mat-form-field>
        <mat-label>Category</mat-label>
        <input type="text" matInput #categoryInput formControlName="categoryID" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option.name }}</mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="
            productForm.controls['categoryID'].hasError('required') ||
            myForm.submitted
          ">Category can not be empty.</mat-error>
      </mat-form-field>

      <!-- Image -->
      <!-- <mat-form-field class="w-100 fs-16" appearance="outline">
          <mat-label>Image</mat-label>
          <input
            matInput
            type="text"
            formControlName="image"
            placeholder="Enter image name"
            readonly
          />
        </mat-form-field> -->
      <!-- <input
          #fileInput
          type="file"
          id="file"
          accept="image/*"
          formControlName="image"
          (change)="onFileSelected($event)"
        /> -->

      <button *ngIf="edit !== 'false'" type="button" mat-raised-button (click)="fileInput.click()" class="mb-3">
        Choose File
      </button>
      <input hidden (change)="onFileSelected($event)" #fileInput accept="image/*" multiple="true" type="file" id="file"
        formControlName="image" />
      <br />
      <div>
        <img *ngFor="let preview of preview" [src]="preview" class="preview mb-2" />
      </div>

      <div *ngIf="edit === 'false'">
        <hr />
        <h4 class="mb-56 d-inline">Add Batch</h4>
        <form #myForm2="ngForm" class="mt-3" [formGroup]="batchForm" (ngSubmit)="onSubmit()">
          <!-- Quantity -->
          <mat-form-field class="w-25 fs-16" appearance="outline">
            <mat-label>Total Quantity</mat-label>
            <input matInput type="number" formControlName="quantity" placeholder="Enter Total Quantity" />
            <mat-error *ngIf="
                batchForm.controls['quantity'].hasError('required') ||
                myForm2.submitted
              ">Quantity can not be empty.</mat-error>
          </mat-form-field>

          <!-- Master QR Code -->
          <!-- <mat-form-field class="w-25 fs-16 ms-3" appearance="outline">
            <mat-label>Choose an option</mat-label>
            <mat-select formControlName="batch" [(value)]="selectedBatch">
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="20">20</mat-option>
              <mat-option [value]="30">30</mat-option>
              <mat-option [value]="40">40</mat-option>
              <mat-option [value]="50">50</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              batchForm.controls['batch'].hasError('required') ||
              myForm2.submitted
            "
            >Quantity can not be empty.</mat-error
          > -->
          <button mat-raised-button color="primary" type="button" class="ms-3" [disabled]="isSubmitting"
            (click)="onSubmit()">
            Add
          </button>
          <br />
          <div *ngFor="let qrCode of myAngularxQrCode" class="d-inline-grid">
            <qr-code [id]="qrCode" [hidden]="myAngularxQrCode" [value]="qrCode" size="20" errorCorrectionLevel="L">
            </qr-code>
          </div>
          <a (click)="download()" *ngIf="myAngularxQrCode" mat-raised-button download="qrcode">Download</a>
        </form>
      </div>

      <button *ngIf="isAdd" [disabled]="productForm.errors || productForm.invalid || isSubmitting" mat-raised-button
        color="primary" type="submit">
        Submit
      </button>
      <button *ngIf="!isAdd && edit !== 'false'" mat-raised-button color="primary" type="button" (click)="onEdit()">
        Edit
      </button>
    </form>
  </div>
</div>