import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-dialog-box',
  templateUrl: './edit-dialog-box.component.html',
  styleUrls: ['./edit-dialog-box.component.scss']
})
export class EditDialogBoxComponent {
  public form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<EditDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(){
    this.initializeForm()
  }

  initializeForm(){
  this.form = this.fb.group({
    state: [this.data.initialValue, [Validators.required, Validators.minLength(3)]]
  });
}
  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
