<div class="card">
  <div class="card-body">
    <div class="mb-56">
      <h4 class="mb-56 d-inline" *ngIf="isAdd">Add User</h4>
      <h4 class="mb-56 d-inline" *ngIf="!isAdd">Edit User</h4>
      <div class="button-row mb-4 d-inline float-end">
        <button
          *ngIf="isAdd"
          mat-raised-button
          class="me-8 mb-8"
          [routerLink]="['../users']"
          color="primary"
        >
          User List
        </button>
        <button
          *ngIf="!isAdd"
          mat-raised-button
          class="me-8 mb-8"
          [routerLink]="['../../users']"
          color="primary"
        >
          User List
        </button>
      </div>
    </div>
    <form
      [formGroup]="userForm"
      (ngSubmit)="onSubmit()"
      enctype="multipart/form-data"
      #myForm="ngForm"
    >
      <!-- CompanyName -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Company Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="companyName"
          placeholder="Enter your company name"
        />
        <mat-error
          *ngIf="
            userForm.controls['companyName'].hasError('required') ||
            myForm.submitted
          "
          >Company Name can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- Username -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Username</mat-label>
        <input
          matInput
          type="text"
          formControlName="username"
          placeholder="Enter your username"
        />
        <mat-error
          *ngIf="
            userForm.controls['username'].hasError('required') ||
            myForm.submitted
          "
          >Username can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- Email -->
      <!-- <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="text"
          formControlName="email"
          placeholder="Enter Email"
        />
        <mat-error
          *ngIf="
            userForm.controls['email'].hasError('email') || myForm.submitted
          "
          >Please check the mail address again.</mat-error
        >
      </mat-form-field> -->

      <!-- Password -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          formControlName="password"
          placeholder="Enter Password"
          minlength="6"
        />
        <button type="button"
          mat-icon-button
          matIconSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon matSuffix>{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>

        <mat-error
          *ngIf="
            userForm.controls['password'].hasError('required') ||
            myForm.submitted
          "
          >Password can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- Phone -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Phone</mat-label>
        <input
          matInput
          type="tel"
          formControlName="phone"
          placeholder="Enter phone number"
          maxlength="10"
        />
        <mat-error
          *ngIf="
            userForm.controls['phone'].hasError('required') || myForm.submitted
          "
          >Phone number can not be empty.</mat-error
        >
        <mat-error
          *ngIf="
            userForm.controls['phone'].hasError('pattern') || myForm.submitted
          "
          >Phone number must be number and must be exactly 10 digits</mat-error
        >
      </mat-form-field>

      <!-- State -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>State</mat-label>
        <input
          type="text"
          matInput
          formControlName="state"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          (optionSelected)="stateSelected($event)"
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of filteredState | async"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-autocomplete>
        <mat-error
          *ngIf="
            userForm.controls['state'].hasError('required') || myForm.submitted
          "
          >State can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- City -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>City</mat-label>
        <input
          type="text"
          matInput
          formControlName="city"
          [matAutocomplete]="city"
        />
        <mat-autocomplete #city="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredCity | async"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-autocomplete>
        <mat-error
          *ngIf="
            userForm.controls['city'].hasError('required') || myForm.submitted
          "
          >City can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- Pincode -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Pincode</mat-label>
        <input
          matInput
          type="text"
          formControlName="pincode"
          placeholder="Enter your pincode"
          maxlength="6"
        />
        <mat-error
          *ngIf="
            userForm.controls['pincode'].hasError('required') ||
            myForm.submitted
          "
          >Pincode can not be empty.</mat-error
        >
        <mat-error
          *ngIf="
            userForm.controls['pincode'].hasError('pattern') || myForm.submitted
          "
          >Please check you pincode.</mat-error
        >
      </mat-form-field>

      <!-- Image -->
      <button
        type="button"
        mat-raised-button
        (click)="fileInput.click()"
        class="mb-3"
      >
        Choose File
      </button>
      <input
        hidden
        (change)="onFileSelected($event)"
        #fileInput
        accept="image/*"
        type="file"
        id="file"
        formControlName="image"
      />
      <br />
      <div>
        <img [src]="preview" class="preview mb-2" />
      </div>

      <button
        *ngIf="isAdd"
        [disabled]="userForm.errors || userForm.invalid"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Submit
      </button>
      <button
        *ngIf="!isAdd"
        mat-raised-button
        color="primary"
        type="button"
        (click)="onEdit()"
      >
        Edit
      </button>
    </form>
  </div>
</div>
