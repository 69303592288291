import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/service/category.service';
import { ProductService } from 'src/app/service/product.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent {
  displayedColumns: string[] = [
    'name',
    'price',
    'description',
    'category',
    'edit',
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = true;
  inStock!: boolean;
  categoryWiseProducts: any;

  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Product List');
    this.getAllProducts();
    this.getAllCategories();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllProducts() {
    this.productService.getAllProducts().subscribe({
      next: async (products) => {
        this.dataSource = await new MatTableDataSource(products.products);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Products',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  getAllCategories() {
    this.categoryService.getAllCategories().subscribe({
      next: async (categories) => {
        this.categoryWiseProducts = categories.categories;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Categories',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  getCategoryWiseProducts(id: string) {
    if (id) {
      this.productService.getCategoryWiseProducts(id).subscribe({
        next: async (products) => {
          this.dataSource = await new MatTableDataSource(products.products);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.spinner = false;
        },
        error: (error) => {
          this.spinner = false;
          this.toastr.error(
            error.message
              ? error.message
              : 'Could not load Categorie wise product',
            'Error'
          );
        },
        complete: () => {},
      });
    } else this.getAllProducts();
  }

  selectCategory(event: string) {
    this.getCategoryWiseProducts(event);
  }

  addProduct() {
    this.router.navigate(['/addProduct']);
  }

  // onDelete(id: string) {
  //   console.log(id);
  //   this.productService.deleteProduct(id).subscribe({
  //     next: (data) => {
  //       console.log(data);
  //       this.getAllProducts();
  //     },
  //     error: (error) => {
  //       console.log(error);
  //     },
  //     complete: () => {},
  //   });
  // }

  onEdit(id: string, edit: boolean) {
    this.router.navigate(['/editProduct/', id], {
      queryParams: { edit: edit },
    });
  }

  setProductOutOfStock(id: string, inStock: boolean) {
    const data = {
      productId: id,
      inStock: inStock,
    };
    this.productService.setProductOutofStock(data).subscribe({
      next: (data) => {},
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not set product out of stock',
          'Error'
        );
      },
      complete: () => {},
    });
  }
}
