import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OutletService } from 'src/app/service/outlet.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-add-edit-outlet',
  templateUrl: './add-edit-outlet.component.html',
  styleUrls: ['./add-edit-outlet.component.scss']
})
export class AddEditOutletComponent {
  addTitle = 'Add Outlet';
  editTitle = 'Edit Outlet';
  hide = true;
  checked = true;
  isAdd = true;
  outletId!: string;
  outletForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [Validators.required,Validators.pattern('^[1-9][0-9]{5}$')]),
    contact: new FormControl('', [Validators.required,Validators.pattern(/^[0-9]{10}$/)]),
    location: new FormControl('', [Validators.required]),
    image: new FormControl(),
  });
  preview: string | undefined;
  currentFile: File | undefined;
  isSubmitting = false;

  constructor(
    private outletService: OutletService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.addTitle);
    this.outletId = this.route.snapshot.params['id'];
    if (this.outletId) {
      this.titleService.setTitle(this.editTitle);
      this.isAdd = false;
      this.getOutletById(this.outletId);
    }
  }

  getOutletById(id: string) {
    this.outletService.getOutletById(id).subscribe({
      next: (data) => {
        this.outletForm.patchValue({
          name: data?.outlet?.name,
          city: data?.outlet?.city,
          pincode: data?.outlet?.pincode,
          contact: data?.outlet?.contact,
          location: data?.outlet?.location,
        });
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not find Outlet Details',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onEdit() {
    const data = {
      outletId: this.outletId,
      name: this.outletForm.value['name'],
      city: this.outletForm.value['city'],
      pincode: this.outletForm.value['pincode'],
      contact: this.outletForm.value['contact'],
      location: this.outletForm.value['location'],
    };
    this.outletService.updateOutlet(data).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Outlet edited successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Outlet could not be edited',
            'Warning'
          );
        }
        this.router.navigate(['../../outlet']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Outlet could not be edited',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onAdd() {
    var formdata: any = new FormData();
    formdata.append('image', this.currentFile);
    formdata.append('name', this.outletForm?.value['name']);
    formdata.append('city', this.outletForm?.value['city']);
    formdata.append('pincode', this.outletForm?.value['pincode']);
    formdata.append('contact', this.outletForm?.value['contact']);
    formdata.append('location', this.outletForm?.value['location']);
    this.outletService.createOutlet(formdata).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Outlet added successfully', 'Success');
        } else {
          this.toastr.warning(
            data?.error?.message
              ? data?.error?.message
              : 'Outlet could not be added',
            'Warning'
          );
        }
        this.router.navigate(['../outlet']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Outlet could not be added',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const file: File | null = selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    if (this.isAdd) {
      this.onAdd();
    } else {
      this.onEdit();
    }
    setTimeout(() => {
      // Reset the flag once the operation is complete
      this.isSubmitting = false;
    }, 3000);
  }
}
