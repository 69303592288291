import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { SliderService } from 'src/app/service/slider.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-add-edit-slider',
  templateUrl: './add-edit-slider.component.html',
  styleUrls: ['./add-edit-slider.component.scss'],
})
export class AddEditSliderComponent {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  addTitle = 'Add Slider';
  editTitle = 'Edit Slider';
  hide = true;
  checked = true;
  isAdd = true;
  sliderId!: string;
  sliderForm = new FormGroup({
    title: new FormControl('', [Validators.required]),
    image: new FormControl('', [Validators.required]),
  });
  preview = '';
  currentFile: any;
  isSubmitting = false;

  constructor(
    private sanitizer: DomSanitizer,
    private sliderService: SliderService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.addTitle);
    this.sliderId = this.route.snapshot.params['id'];
    if (this.sliderId) {
      this.titleService.setTitle(this.editTitle);
      this.isAdd = false;
      this.getBannerById(this.sliderId);
    }
  }

  getBannerById(id: string) {
    this.sliderService.getSliderById(id).subscribe({
      next: (data) => {
        this.sliderForm.patchValue({
          title: data?.slider?.title,
        });
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not load banner.',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onEdit() {
    var formdata: any = new FormData();
    formdata.append('sliderId', this.sliderId);
    formdata.append('image', this.currentFile);
    formdata.append('title', this.sliderForm.value['title']);
    // const data = {
    //   sliderId: this.sliderId,
    //   title: this.sliderForm.value['title'],
    // };
    this.sliderService.updateSlider(formdata).subscribe({
      next: (data) => {
        this.toastr.success('Slider edited successfully', 'Success');
        this.router.navigate(['../../slider']);
      },
      error: (error) => {
        this.toastr.error('Slider could not be edited', 'Error');
      },
      complete: () => { },
    });
  }

  onAdd() {
    var formdata: any = new FormData();
    formdata.append('image', this.currentFile);
    formdata.append('title', this.sliderForm.value['title']);

    this.sliderService.createSlider(formdata).subscribe({
      next: (data) => {
        this.toastr.success('Slider added successfully', 'Success');
        this.router.navigate(['../slider']);
      },
      error: (error) => {
        this.toastr.error('Slider could not be added', 'Error');
      },
      complete: () => { },
    });
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    if (this.isAdd) {
      this.onAdd();
    } else {
      this.onEdit();
    }
    setTimeout(() => {
      // Reset the flag once the operation is complete
      this.isSubmitting = false;
    }, 3000);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent | any) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event?.objectUrl);
    // event.blob can be used to upload the cropped image
    this.currentFile = event.blob;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
