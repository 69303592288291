<div class="d-flex justify-content-center align-items-center h-100">
  <mat-card class="example-card">
    <mat-card-header>
      <!-- <div mat-card-avatar class="example-header-image"></div> -->
      <mat-card-title>Login</mat-card-title>
      <mat-card-subtitle>Admin Login</mat-card-subtitle>
    </mat-card-header>
    <!-- <img
      mat-card-image
      src="https://material.angular.io/assets/img/examples/shiba2.jpg"
      alt="Photo of a Shiba Inu"
    /> -->
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" #myForm="ngForm">
        <div class="example-container">
          <mat-form-field>
            <mat-label>Enter your email</mat-label>
            <input
              matInput
              placeholder="pat@example.com"
              formControlName="email"
              required
            />
            <mat-error *ngIf="loginForm.controls['email'].invalid"
              >Please enter correct email address.</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label>Enter your password</mat-label>
            <input
              matInput
              formControlName="password"
              [type]="hide ? 'password' : 'text'"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
          </mat-form-field>
          <button mat-raised-button color="primary">Submit</button>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions class="justify-content-center">
      <button mat-raised-button color="warn">Forgot Password</button>
      <button mat-raised-button color="primary" class="signUp-btn">
        Sign up
      </button>
    </mat-card-actions>
  </mat-card>
</div>
