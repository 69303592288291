import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-dialog-box',
  templateUrl: './add-dialog-box.component.html',
  styleUrls: ['./add-dialog-box.component.scss']
})
export class AddDialogBoxComponent {
  public form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(){
    this.initializeForm()
  }

  initializeForm(){
  this.form = this.fb.group({
    state: [this.data.state, [Validators.required, Validators.minLength(3)]],
    city: ['', [Validators.required, Validators.minLength(3)]]
  });
}

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.form.valid) {
      console.log(this.form.value);
      
      this.dialogRef.close(this.form.value);
    }
  }
}

