<div class="card">
    <div class="card-body">
        <div class="mb-56">
            <h4 class="mb-56 d-inline" *ngIf="isAdd">Add Outlet</h4>
            <h4 class="mb-56 d-inline" *ngIf="!isAdd">Edit Outlet</h4>
            <div class="button-row mb-4 d-inline float-end">
                <button *ngIf="isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../outlet']" color="primary">
                    Outlet List
                </button>
                <button *ngIf="!isAdd" mat-raised-button class="me-8 mb-8" [routerLink]="['../../outlet']"
                    color="primary">
                    Outlet List
                </button>
            </div>
        </div>
        <form [formGroup]="outletForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data" #myForm="ngForm">
            <!-- Outlet name -->
            <mat-form-field class="w-100 fs-16" appearance="outline">
                <mat-label>Outlet Name</mat-label>
                <input matInput type="text" formControlName="name" placeholder="Enter the outlet" />
                <mat-error *ngIf="
              outletForm.controls['name'].hasError('required') ||
              myForm.submitted
            ">Name can not be empty.</mat-error>
            </mat-form-field>


            <!-- City name -->
            <mat-form-field class="w-100 fs-16" appearance="outline">
                <mat-label>City Name</mat-label>
                <input matInput type="text" formControlName="city" placeholder="Enter your City" />
                <mat-error *ngIf="
                outletForm.controls['city'].hasError('required') ||
                myForm.submitted
              ">City can not be empty.</mat-error>
            </mat-form-field>

            <!-- Pincode name -->
            <mat-form-field class="w-100 fs-16" appearance="outline">
                <mat-label>Pincode</mat-label>
                <input matInput type="tel" formControlName="pincode" placeholder="Enter your pincode" maxlength="6" />
                <mat-error *ngIf="
                outletForm.controls['pincode'].hasError('required') ||
                myForm.submitted
              ">Pincode can not be empty.</mat-error>
              <mat-error *ngIf="
                outletForm.controls['pincode'].hasError('pattern') ||
                myForm.submitted
              ">Please check you pincode.</mat-error>
            </mat-form-field>

            <!-- Contact Information -->
            <mat-form-field class="w-100 fs-16" appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput type="tel" formControlName="contact" placeholder="Enter your number" maxlength="10"/>
                <mat-error *ngIf="
                outletForm.controls['contact'].hasError('required') ||
                myForm.submitted
              ">Phone number can not be empty.</mat-error>
              <mat-error *ngIf="
                outletForm.controls['contact'].hasError('pattern') ||
                myForm.submitted
              "><mat-error *ngIf="
                outletForm.controls['contact'].hasError('required') ||
                myForm.submitted
              ">Phone number must be number and must be exactly 10 digits</mat-error></mat-error>
            </mat-form-field>

            <!-- Location -->
            <mat-form-field class="w-100 fs-16" appearance="outline">
                <mat-label>Loction</mat-label>
                <input matInput type="text" formControlName="location" placeholder="Paste your google location" />
                <mat-error *ngIf="
                outletForm.controls['location'].hasError('required') ||
                myForm.submitted
              ">Location can not be empty.</mat-error>
            </mat-form-field>

            <!-- Image -->
            <button type="button" mat-raised-button (click)="fileInput.click()" class="mb-3">
                Choose File
            </button>
            <input hidden (change)="onFileSelected($event)" #fileInput accept="image/*" type="file" id="file"
                formControlName="image" />
            <br />
            <div>
                <img [src]="preview" class="preview mb-2" />
            </div>

            <button *ngIf="isAdd" [disabled]="outletForm.errors || outletForm.invalid || isSubmitting" mat-raised-button
                color="primary" type="submit">
                Submit
            </button>
            <button *ngIf="!isAdd" mat-raised-button color="primary" type="button" (click)="onEdit()">
                Edit
            </button>
        </form>
    </div>
</div>