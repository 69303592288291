import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SliderService } from 'src/app/service/slider.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent {
  displayedColumns: string[] = ['title', 'image', 'action'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = true;

  constructor(
    private sliderService: SliderService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Banner List');
    this.getAllSlider();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllSlider() {
    this.sliderService.getAllSliders().subscribe({
      next: async (sliders) => {
        this.dataSource = await new MatTableDataSource(sliders.slider);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Sliders',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  addSlider() {
    this.router.navigate(['/addSlider']);
  }

  onDelete(id: string) {
    this.sliderService.deleteSlider(id).subscribe({
      next: (data) => {
        this.getAllSlider();
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not delete Slider',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onEdit(id: string) {
    this.router.navigate(['/editSlider/', id]);
  }
}
