import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { DemoFlexyModule } from '../demo-flexy-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent } from './users/users.component';
import { AddEditAdminComponent } from './admin/add-edit-admin/add-edit-admin.component';
import { AppRoutingModule } from '../app-routing.module';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { AddEditUsersComponent } from './users/add-edit-users/add-edit-users.component';
import { AddEditCategoryComponent } from './category/add-edit-category/add-edit-category.component';
import { AddEditSubCategoryComponent } from './sub-category/add-edit-sub-category/add-edit-sub-category.component';
import { ProductsComponent } from './products/products.component';
import { AddEditProductComponent } from './products/add-edit-product/add-edit-product.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { LeaderboardComponent } from './users/leaderboard/leaderboard.component';
import { AddEditCatalogueComponent } from './catalogue/add-edit-catalogue/add-edit-catalogue.component';
import { SettingsComponent } from './settings/settings.component';
import { SliderComponent } from './slider/slider.component';
import { AddEditSliderComponent } from './slider/add-edit-slider/add-edit-slider.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { OutletComponent } from './outlet/outlet.component';
import { AddEditOutletComponent } from './outlet/add-edit-outlet/add-edit-outlet.component';
import { QrCodeModule } from 'ng-qrcode';
import { AddEditCityStateComponent } from './add-edit-city-state/add-edit-city-state.component';
import { AddDialogBoxComponent } from './add-dialog-box/add-dialog-box.component';
import { AddStateDialogBoxComponent } from './add-state-dialog-box/add-state-dialog-box.component';

@NgModule({
  imports: [
    CommonModule,
    FeatherModule.pick(allIcons),
    DemoFlexyModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    QrCodeModule,
    ImageCropperComponent,
  ],
  exports: [],
  declarations: [
    AdminComponent,
    UsersComponent,
    AddEditAdminComponent,
    CategoryComponent,
    SubCategoryComponent,
    AddEditUsersComponent,
    AddEditCategoryComponent,
    AddEditSubCategoryComponent,
    ProductsComponent,
    AddEditProductComponent,
    BatchListComponent,
    CatalogueComponent,
    LeaderboardComponent,
    AddEditCatalogueComponent,
    SettingsComponent,
    SliderComponent,
    AddEditSliderComponent,
    OutletComponent,
    AddEditOutletComponent,
    AddEditCityStateComponent,
    AddDialogBoxComponent,
    AddStateDialogBoxComponent,
  ],
})
export class ComponentsModule { }
