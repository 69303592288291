import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OutletService {

  constructor(private baseService: BaseService) {}

  createOutlet(data: any): Observable<any> {
    return this.baseService.post('outlet/addOutlet', data);
  }

  updateOutlet(data: any): Observable<any> {
    return this.baseService.post(`outlet/editOutlet`, data);
  }

  getAllOutlets(): Observable<any> {
    return this.baseService.get('outlet/listOutlets');
  }

  getOutletById(data: any): Observable<any> {
    return this.baseService.get('outlet/getOutlet', data);
  }

  deleteOutlet(data: any): Observable<any> {
    return this.baseService.delete('outlet/deleteOutlet', data);
  }
}
