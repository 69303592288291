import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private baseService: BaseService) { }

  getAppVersion(): Observable<any> {
    return this.baseService.get('settings/getAppVersion');
  }

  getSocialLinks(): Observable<any> {
    return this.baseService.get('settings/getSocialLinks');
  }

  updateSocialLinks(data: any): Observable<any> {
    return this.baseService.post('settings/updateSocilaLinks', data);
  }

  updateAppVersion(data: any): Observable<any> {
    return this.baseService.post('settings/updateAppVersion', data);
  }

  updateTermsCondition(data: any): Observable<any> {
    return this.baseService.post(`settings/uploadTermsCondition`, data);
  }

  // getAllTermsConditions(): Observable<any> {
  //   return this.baseService.get('termsCondition/listTermsCondition');
  // }

}
