<mat-sidenav-container class="sidenav-container">
  <!-- Sidebar -->
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <div class="p-16 top-padding">
      <div class="margin-top">
        <a href="javascript:void(0)" class="d-flex align-items-center">
          <img src="assets/images/C.png" class="logo" alt="" />
          <!-- <span class="ms-16 fw-medium fs-26">Olivar Int.</span> -->
        </a>
      </div>

      <div class="margin-top">
        <ul>
          <li class="list-group mb-8" *ngFor="let sidenav of sidebarMenu">
            <a
              matRipple
              [matRippleColor]="'#f7f8f443'"
              routerLink="{{ sidenav.link }}"
              class="sidebar-menu fs-16 w-100 d-flex align-items-center"
              [routerLinkActive]="routerActive"
              (click)="toggleSubmenu(sidenav)"
            >
              <mat-icon
                aria-hidden="false"
                class="me-16 material-icons material-icons-outlined"
                fontIcon="{{ sidenav.icon }}"
              ></mat-icon>
              <!-- <i-feather
                name="{{ sidenav.icon }}"
                class="feather-base me-16"
              ></i-feather> -->
              <span class="ps-4">{{ sidenav.menu }}</span>
              <mat-icon *ngIf="sidenav.submenus.length" class="expand-icon">
                {{ sidenav.expanded ? "expand_less" : "expand_more" }}
              </mat-icon>
            </a>
            <ul
              *ngIf="sidenav.submenus.length && sidenav.expanded"
              class="submenu"
            >
              <li
                class="list-group mb-8"
                *ngFor="let submenu of sidenav.submenus"
              >
                <a
                  matRipple
                  [matRippleColor]="'#f7f8f443'"
                  [routerLink]="submenu.link"
                  class="sidebar-menu fs-16 w-100 d-flex align-items-center"
                  [routerLinkActive]="routerActive"
                >
                  <mat-icon
                    aria-hidden="false"
                    class="me-16 material-icons material-icons-outlined"
                    fontIcon="{{ submenu.icon }}"
                  ></mat-icon>
                  <span class="ps-4">{{ submenu.menu }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- Header -->
    <mat-toolbar class="header ps-24 pe-24">
      <button
        type="button"
        class="ms-4"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <i-feather
          name="menu"
          class="text-light-muted feather-base mt-n3"
        ></i-feather>
      </button>
      <button mat-icon-button class="ms-4 me-4" (click)="search = !search">
        <i-feather
          name="search"
          class="mt-n3 text-light-muted feather-base text-dark"
        ></i-feather>
      </button>
      <div class="ms-auto">
        <button
          mat-button
          class="fs-16 pt-4 pb-4 ps-8 pe-8 text-light-muted d-flex align-items-center"
          [matMenuTriggerFor]="menu"
        >
          <div class="d-flex align-items-center justify-content-end">
            <!-- <img
              src="{{ server + '/' + userDetails?.image }}"
              class="rounded-circle admin-image"
              alt=""
            /> -->
            <span class="fw-light ms-8 text-secondary">Hi,</span>
            <span class="fw-bold ms-8">{{ userDetails?.username }}</span>
            <i-feather name="chevron-down" class="feather-base"></i-feather>
          </div>
        </button>
        <mat-menu #menu="matMenu" class="profile-dropdown">
          <div class="p-16 mb-4">
            <button mat-menu-item class="fs-16">Edit Profile</button>
            <button mat-menu-item class="fs-16">Account</button>
            <button mat-menu-item class="fs-16">Change Password</button>
            <button mat-menu-item class="fs-16">My Settings</button>
          </div>

          <hr />

          <div class="p-16">
            <button
              mat-flat-button
              color="accent"
              (click)="logout()"
              class="w-100 text-white"
            >
              Logout
            </button>
          </div>
        </mat-menu>
      </div>
    </mat-toolbar>

    <!-- Content -->
    <div class="body-wrapper">
      <div class="page-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>

    <p class="text-center pb-24 fs-16">
      © 2023 All rights reserved by
      <a
        href="https://olivarinternational.com/"
        target="_blank"
        class="text-indigo"
        >Olivar International</a
      >
    </p>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- Search Box -->
<div *ngIf="search" class="search-section d-flex align-items-center">
  <mat-form-field class="w-100" color="primary">
    <mat-label class="fs-16">Search Here</mat-label>
    <input matInput />
  </mat-form-field>
  <button mat-icon-button (click)="search = !search">
    <i-feather
      name="x-circle"
      class="mt-n3 text-light-muted feather-base text-dark"
    ></i-feather>
  </button>
</div>
