<mat-card>
  <mat-card-header>
    <mat-card-title>Socials</mat-card-title>
    <mat-card-subtitle>All social Media links are here.</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <section>
      <div class="example-button-row">
        <div class="example-flex-container">
          <div class="example-button-container">
            <button (click)="buttonClick(socialForm.value['instaLink'])" mat-fab color="primary"
              aria-label="button with a instagram icon">
              <i-feather name="instagram"></i-feather>
            </button>
            <button (click)="buttonClick(socialForm.value['youtubeLink'])" mat-fab color="primary" class="ms-2"
              aria-label="button with a youtube icon">
              <i-feather name="youtube"></i-feather>
            </button>
            <button (click)="buttonClick(socialForm.value['facebookLink'])" mat-fab color="primary" class="ms-2"
              aria-label="button with a facebook icon">
              <i-feather name="facebook"></i-feather>
            </button>
            <button (click)="buttonClick(socialForm.value['linkedinLink'])" mat-fab color="primary" class="ms-2"
              aria-label="button with a linkedin icon">
              <i-feather name="linkedin"></i-feather>
            </button>
            <button (click)="buttonClick(socialForm.value['whatsAppLink'])" mat-fab color="primary" class="ms-2"
              aria-label="button with a whatsapp icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp"
                viewBox="0 0 16 16">
                <path
                  d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
    <div>
      <mat-card-actions>
        <button class="mt-3" mat-raised-button color="primary" type="button" (click)="showInput()">
          Edit Links
        </button>
      </mat-card-actions>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="showInputFlag">
    <form [formGroup]="socialForm" (ngSubmit)="onSubmit(1)" enctype="multipart/form-data" #myForm="ngForm">
      <!-- Instagram Link -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Instagram Link</mat-label>
        <input matInput type="text" formControlName="instaLink" placeholder="Enter the Instagram link here" />
      </mat-form-field>

      <!-- Youtube Link -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Youtube Link</mat-label>
        <input matInput type="text" formControlName="youtubeLink" placeholder="Enter the Youtube link here" />
      </mat-form-field>

      <!-- Facebook Link -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Facebook Link</mat-label>
        <input matInput type="text" formControlName="facebookLink" placeholder="Enter the Facebook link here" />
      </mat-form-field>

      <!-- LinkedIn Link -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>LinkedIn Link</mat-label>
        <input matInput type="text" formControlName="linkedinLink" placeholder="Enter the LinkedIn link here" />
      </mat-form-field>

      <!-- Whatsapp Link -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Whatsapp Link</mat-label>
        <input matInput type="text" formControlName="whatsAppLink" placeholder="Enter the Whatsapp link here" />
      </mat-form-field>

      <button [disabled]="
      socialForm.errors || socialForm.invalid || isSubmitting
    " mat-raised-button color="primary" type="submit">
        Submit
      </button>
    </form>
  </mat-card-content>
</mat-card>
<br />
<div class="spinner" *ngIf="spinner">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="!spinner">
  <mat-card>
    <mat-card-header>
      <mat-card-title>App Info</mat-card-title>
      <mat-card-subtitle>You can find the version information here.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p class="ms-3">Click on button to get the current version of app.</p>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="getAppVersion()" class="ms-1">Get Version</button>
        <p class="ms-3" *ngIf="currentAppVersion != null">{{ 'App Version - ' + currentAppVersion }}</p>
      </mat-card-actions>
      <hr />
      <p class="ms-3">Click on button to update the app of all users.</p>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="updateAppVersion()">Update app</button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>

<!-- Terms and condition  -->
<div class="card">
  <div class="card-body">
    <div class="mb-56">
      <h4 class="mb-56 d-inline">Edit Terms & Condition</h4>
      <div class="button-row mb-4 d-inline float-end">
        <button mat-raised-button class="me-8 mb-8" (click)="updateTermsAndCondition()" color="primary">
          Edit Terms & Condition
        </button>
      </div>
    </div>
    <div *ngIf="termsFlag">
      <form [formGroup]="termsConditionForm" (ngSubmit)="onSubmit(2)" enctype="multipart/form-data" #myForm="ngForm">
        <!-- Terms & Condition name -->
        <mat-form-field class="w-100 fs-16" appearance="outline">
          <mat-label>Terms & Condition Title</mat-label>
          <input matInput type="text" formControlName="title" placeholder="Enter the Terms & Condition Title" />
          <mat-error *ngIf="
              termsConditionForm.controls['title'].hasError('required') ||
              myForm.submitted
            ">Title can not be empty.</mat-error>
        </mat-form-field>

        <!-- Pdf -->
        <button type="button" mat-raised-button (click)="fileInput.click()" class="mb-3">
          Choose File
        </button>
        <input hidden #fileInput accept="application/pdf" type="file" id="file" formControlName="pdf"
          (change)="onFileChange($event)" />
        <p *ngIf="pdfFileName" class="d-inline-block ms-3">{{ pdfFileName }}</p>
        <br />

        <button [disabled]="
            termsConditionForm.errors || termsConditionForm.invalid || isSubmitting
          " mat-raised-button color="primary" type="submit">
          Submit
        </button>
      </form>
    </div>
  </div>
</div>