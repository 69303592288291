import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private baseService: BaseService) { }

  createUser(data: any): Observable<any> {
    return this.baseService.post('user/register', data);
  }

  updateUser(data: any): Observable<any> {
    return this.baseService.post(`user/update/${data?.userId}`, data);
  }

  getAllUsers(): Observable<any> {
    return this.baseService.get('user/list');
  }

  getUserById(data: any): Observable<any> {
    return this.baseService.get('user/account', data);
  }

  deleteUser(data: any): Observable<any> {
    return this.baseService.delete('user/delete', data);
  }

  getState(): Observable<any> {
    return this.baseService.get('user/getState');
  }

  getCity(data: any): Observable<any> {
    return this.baseService.get(`user/getCity/${data}`);
  }

  deleteState(id: any): Observable<any> {
    return this.baseService.delete('user/deleteState',id)
  }

  deleteCity(id:any): Observable<any> {
    return this.baseService.delete('user/deleteCity',id)
  }

  editState(data: any): Observable<any> {
    return this.baseService.patch(`user/editState/${data?.id}`, data)
  }

  editCity(data: any): Observable<any> {
    return this.baseService.patch(`user/editCity/${data?.id}`, data)
  }

  addCity(data:any): Observable<any>{
    return this.baseService.post('user/addCity',data)
  }

  addState(data: any): Observable<any>{
    return this.baseService.post('user/addState',data)
  }

  generateReport(data: any): Observable<any> {
    return this.baseService.post('registeredProducts/generateReports', data);
  }
}
