import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SafeUrl, Title } from '@angular/platform-browser';
import jsPDF from 'jspdf';
import { ProductService } from 'src/app/service/product.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss'],
})
export class BatchListComponent {
  displayedColumns: string[] = [
    'productName',
    'quantity',
    'registerDate',
    'stickerFlag',
    'action',
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = true;
  productObject: any;
  batchObject: any;
  qrCodeDownloadLink: SafeUrl | undefined;
  myAngularxQrCode: any;
  qrvalue: any;

  constructor(
    private productService: ProductService,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) { }

  async ngOnInit() {
    this.titleService.setTitle('Batch List');
    await this.getAllProducts();
    await this.getAllBatches();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllProducts() {
    this.productService.getAllProducts().subscribe({
      next: (products) => {
        this.productObject = products.products;
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not load Products',
          'Error'
        );
        this.spinner = false;
      },
      complete: () => { },
    });
  }

  getAllBatches() {
    this.productService.getAllBatches().subscribe({
      next: async (batches) => {
        this.batchObject = await batches.batches.map((batch: any) => {
          return {
            productName: batch?.productId?.name,
            quantity: batch.quantity,
            productCodes: batch.productCodes,
            productId: batch?.productId?._id,
            batchId: batch._id,
            registerDate: batch.registerDate,
            stickerFlag: this.productObject?.find(
              (i: any) => i._id === batch?.productId?._id
            )?.category?.stickerFlag,
          };
        });
        this.dataSource = new MatTableDataSource(this.batchObject);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Batches',
          'Error'
        );
      },
      complete: () => { },
    });
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  getBase64Image(img: any) {
    // var canvas = document.createElement('canvas');
    // canvas.width = img?.width;
    // canvas.height = img?.height;
    // var ctx = canvas.getContext('2d');
    // ctx?.drawImage(img, 0, 0);
    var dataURL = img.toDataURL('image/png');
    return dataURL;
  }

  async onDownload(batch: any) {
    console.log(batch, 'batch');
  
    this.myAngularxQrCode = batch.productCodes;
    this.myAngularxQrCode = this.myAngularxQrCode.map((code: any) => {
      return JSON.stringify({
        productId: batch.productId,
        productCode: code,
        batchId: batch.batchId,
      });
    });
  
    let imgDataArr = [];
    let codesArr = [];
  
    for (const f of this.myAngularxQrCode) {
      this.qrvalue = f;
      console.log(this.qrvalue, 'value');
      
      while (!document.querySelector('qr-code canvas')) {
        await new Promise((r) => setTimeout(r, 500));
      }
  
      const qrcode = document.getElementById(f);
      let imageData = this.getBase64Image(qrcode?.firstChild);
      imgDataArr.push(imageData);
  
      // Extract the product code from JSON string
      let jsonData = JSON.parse(f);
      codesArr.push(jsonData.productCode);
    }
  
    this.generatePDF(imgDataArr, codesArr, batch.productName, batch.stickerFlag);
  }
  

  getFormattedDate(): string {
    const date = new Date();
    const pad2 = (n: any) => (n < 10 ? '0' + n : n);
    return `${date.getFullYear()}${pad2(date.getMonth() + 1)}${pad2(
      date.getDate()
    )}${pad2(date.getHours())}${pad2(date.getMinutes())}${pad2(
      date.getSeconds()
    )}`;
  }

  generatePDF(imgDataArr: string[], codesArr: string[], productName: string, stickerFlag: string) {
    if (stickerFlag == '0') {
      //  1 * 1 BARCODE STICKER 50 by 50 mm flag 0
      let doc = new jsPDF('p', 'mm', [50, 50]);
      let x = 2.5;
      let y = 2.5;
      let w = 45;
      let h = 45;
      let pageWidth = doc.internal.pageSize.getWidth();
      let index = 0;
      const lastIndex = imgDataArr.length - 1;
      for (const data of imgDataArr) {
        doc.addImage(data, 'JPEG', x, y, w, h);
              // Add product code text below the image
      const productCode = `${codesArr[index]}`
      doc.setFontSize(10);
      const textY = y + h + 1; 
      doc.text(productCode, x + w / 2, textY, { align: 'center' });
        if (index == lastIndex) {
          break;
        }
        index++;
        doc.addPage([50, 50]);
      }

      const fileName = `${productName}_${this.getFormattedDate()}`;
      doc.save(fileName);
    } else if (stickerFlag == '1') {
      // 1 * 4 BARCODE STICKER 25 by 100 mm flag 1
      let doc = new jsPDF('p', 'mm', [25, 100]);
      let x = 0;
      let y = 0;
      let w = 22;
      let h = 22;
      let pageWidth = doc.internal.pageSize.getWidth();
      let index = 0;
      const lastIndex = imgDataArr.length - 1;
      for (const data of imgDataArr) {
        doc.addImage(data, 'JPG', x, y, w, h);
              // Add product code text below the image
      const productCode = `${codesArr[index]}`
      doc.setFontSize(6);
      const textY = y + h ; 
      doc.text(productCode, x + w / 2, textY, { align: 'center' });
        // x = x + 22;
        if (index == lastIndex) {
          break;
        }
        index++;
        y = y + 25;
        if (y >= 80) {
          doc.addPage([25, 100]);
          x = 0;
          y = 2;
        }
      }

      const fileName = `${productName}_${this.getFormattedDate()}`;
      doc.save(fileName);
    }
  }
  
  

  // addCategory() {
  //   this.router.navigate(['/addCategory']);
  // }

  // onDelete(id: string) {
  //   console.log(id);
  //   this.productService.deleteCategory(id).subscribe({
  //     next: (data) => {
  //       console.log(data);
  //       this.getAllCategories();
  //     },
  //     error: (error) => {
  //       console.log(error);
  //     },
  //     complete: () => {},
  //   });
  // }

  // onEdit(id: string) {
  //   this.router.navigate(['/editCategory/', id]);
  // }
}
