import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/service/admin.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  displayedColumns: string[] = [
    'username',
    'email',
    'phone',
    'image',
    'action',
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = true;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Admin List');
    this.getAllAdmins();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllAdmins() {
    this.adminService.getAllAdmins().subscribe({
      next: async (admins) => {
        this.dataSource = await new MatTableDataSource(admins.admins);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Admins',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  addAdmin() {
    this.router.navigate(['/addAdmin']);
  }

  onDelete(id: string) {
    this.adminService.deleteAdmin(id).subscribe({
      next: (data) => {
        this.toastr.success('Admin Deleted Successfully', 'Success');
        this.getAllAdmins();
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not delete Admin',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onEdit(id: string) {
    this.router.navigate(['/editAdmin/', id]);
  }
}
