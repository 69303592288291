<div class="card">
  <div class="card-body">
    <div class="mb-56">
      <h4 class="mb-56 d-inline" *ngIf="isAdd">Add Category</h4>
      <h4 class="mb-56 d-inline" *ngIf="!isAdd">Edit Category</h4>
      <div class="button-row mb-4 d-inline float-end">
        <button
          *ngIf="isAdd"
          mat-raised-button
          class="me-8 mb-8"
          [routerLink]="['../category']"
          color="primary"
        >
          Category List
        </button>
        <button
          *ngIf="!isAdd"
          mat-raised-button
          class="me-8 mb-8"
          [routerLink]="['../../category']"
          color="primary"
        >
          Category List
        </button>
      </div>
    </div>
    <form
      [formGroup]="categoryForm"
      (ngSubmit)="onSubmit()"
      enctype="multipart/form-data"
      #myForm="ngForm"
    >
      <!-- Category name -->
      <mat-form-field class="w-100 fs-16" appearance="outline">
        <mat-label>Category Name</mat-label>
        <input
          matInput
          type="text"
          formControlName="name"
          placeholder="Enter the category"
        />
        <mat-error
          *ngIf="
            categoryForm.controls['name'].hasError('required') ||
            myForm.submitted
          "
          >Name can not be empty.</mat-error
        >
      </mat-form-field>

      <!-- Sticker size -->
      <mat-form-field class="w-25 fs-16" appearance="outline">
        <mat-label>Sticker Size</mat-label>
        <mat-select formControlName="stickerFlag">
          <mat-option [value]="1">25 * 25</mat-option>
          <mat-option [value]="0">50 * 50</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error
        *ngIf="
          (categoryForm.controls['stickerFlag'].hasError('required') &&
            categoryForm.controls['stickerFlag'].touched) ||
          (myForm.submitted && myForm.invalid)
        "
        >Sticker Size can not be empty</mat-error
      >
      <br />
      <!-- Image -->
      <button
        type="button"
        mat-raised-button
        (click)="fileInput.click()"
        class="mb-3"
      >
        Choose File
      </button>
      <input
        hidden
        (change)="onFileSelected($event)"
        #fileInput
        accept="image/*"
        type="file"
        id="file"
        formControlName="image"
      />
      <br />
      <div>
        <img [src]="preview" class="preview mb-2" />
      </div>

      <button
        *ngIf="isAdd"
        [disabled]="categoryForm.errors || categoryForm.invalid || isSubmitting"
        mat-raised-button
        color="primary"
        type="submit"
      >
        Submit
      </button>
      <button
        *ngIf="!isAdd"
        mat-raised-button
        color="primary"
        type="button"
        (click)="onEdit()"
      >
        Edit
      </button>
    </form>
  </div>
</div>
