<div>
  <div class="row">
    <div class="col-lg-4">
      <h1>Welcome to,</h1>
    </div>
    <div class="col-lg-8"></div>
  </div>
  <div class="row justify-content-center align-content-center">
    <img src="assets/images/C.png" alt="Olivar International" class="w-50" />
  </div>
</div>
