import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/service/category.service';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';

@Component({
  selector: 'app-add-edit-category',
  templateUrl: './add-edit-category.component.html',
  styleUrls: ['./add-edit-category.component.scss'],
})
export class AddEditCategoryComponent {
  addTitle = 'Add Category';
  editTitle = 'Edit Category';
  hide = true;
  checked = true;
  isAdd = true;
  categoryId!: string;
  categoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    stickerFlag: new FormControl('', [Validators.required]),
    image: new FormControl(),
  });
  preview: string | undefined;
  currentFile: File | undefined;
  isSubmitting = false;

  constructor(
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.addTitle);
    this.categoryId = this.route.snapshot.params['id'];
    if (this.categoryId) {
      this.titleService.setTitle(this.editTitle);
      this.isAdd = false;
      this.getCategoryById(this.categoryId);
    }
  }

  getCategoryById(id: string) {
    this.categoryService.getCategoryById(id).subscribe({
      next: (data) => {
        this.categoryForm.patchValue({
          name: data?.category?.name,
          stickerFlag: data?.category?.stickerFlag,
        });
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not find Category Details',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onEdit() {
    const data = {
      categoryId: this.categoryId,
      name: this.categoryForm.value['name'],
      stickerFlag: this.categoryForm.value['stickerFlag'],
    };
    this.categoryService.updateCategory(data).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Category edited successfully', 'Success');
        } else {
          this.toastr.warning(
            data.error.message
              ? data.error.message
              : 'Category could not be edited',
            'Warning'
          );
        }
        this.router.navigate(['../../category']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Category could not be edited',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onAdd() {
    var formdata: any = new FormData();
    formdata.append('image', this.currentFile);
    formdata.append('name', this.categoryForm?.value['name']);
    formdata.append('stickerFlag', this.categoryForm?.value['stickerFlag']);
    this.categoryService.createCategory(formdata).subscribe({
      next: (data) => {
        if (data.success) {
          this.toastr.success('Category added successfully', 'Success');
        } else {
          this.toastr.warning(
            data?.error?.message
              ? data?.error?.message
              : 'Category could not be added',
            'Warning'
          );
        }
        this.router.navigate(['../category']);
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Category could not be added',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const file: File | null = selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  onSubmit() {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    if (this.isAdd) {
      this.onAdd();
    } else {
      this.onEdit();
    }
    setTimeout(() => {
      // Reset the flag once the operation is complete
      this.isSubmitting = false;
    }, 3000);
  }
}
