import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrNotificationService } from 'src/app/service/toastr-notification.service';
import { UsersService } from 'src/app/service/users.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  displayedColumns: string[] = [
    'companyName',
    'username',
    'address',
    'phone',
    'image',
    'action',
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  environmentURL = environment.apiUrl;
  spinner: boolean = true;

  constructor(
    private userService: UsersService,
    private router: Router,
    private titleService: Title,
    private toastr: ToastrNotificationService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('User List');
    this.getAllUsers();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllUsers() {
    this.userService.getAllUsers().subscribe({
      next: async (users) => {
        this.dataSource = await new MatTableDataSource(users.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.spinner = false;
      },
      error: (error) => {
        this.spinner = false;
        this.toastr.error(
          error.message ? error.message : 'Could not load Users',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  addUser() {
    this.router.navigate(['/addUser']);
  }

  onDelete(id: string) {
    this.userService.deleteUser(id).subscribe({
      next: (data) => {
        this.getAllUsers();
      },
      error: (error) => {
        this.toastr.error(
          error.message ? error.message : 'Could not delete user',
          'Error'
        );
      },
      complete: () => {},
    });
  }

  onEdit(id: string) {
    this.router.navigate(['/editUser/', id]);
  }
}
